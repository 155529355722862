
/*   -=========== Fast order ===========-   */

.fast-order .vitrine {
  margin-left: 0;
}

@media $not-small-mobile {
  .fast-order {
    display: flex;
  }
}

.fast-order--form {
  @media $not-small-mobile {
    margin-left: 35px;
  }

  @media $small-mobile {
    margin-top: 30px;

    .form-submit {
      text-align: center;
    }
  }
}

.fast-order--image {
  display: block;
  margin-bottom: 10px;

  @media $not-small-mobile {
    width: 200px;
  }
}

.fast-order--name {
  font-weight: bold;
  margin-bottom: 5px;

  a {
    text-decoration: none;
  }
}

.fast-order--price {
  font-variant: small-caps;
  font-weight: bold;
  text-align: lowercase;
}


// Фикс формы заказать в 1 клик
.fast-order--product{
  a.product--image{
    height: calc(100% - 160px);
    top: calc(50% - 50px);

    &>img {
      left: 0px;
      top: 120px;
      margin-left: -8px;
      -ms-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
      max-height: 240px;
    }
  }
}

@media $not-small-mobile {
  .fast-order--form{
    .one-click-form {
      .form-hint {
        /* padding-left: 350px; */
        width: 200px;
        padding-right: 30px;
      }
      .form-field {
        width: 230px;
      }
      .submit-fastOrderbasket {
        width: 230px;
      }
    }
  }
}


@media only screen and (max-width: 600px){

  .fast-order-group{
    .product--image{
      display: none;
    }
    .product--price{
      display: flex;
    }
    .product-price--label{
      padding-right: 10px;
    }
  }

  .fast-order--form{
    margin-left: 0;

    .h3{
      margin-top: 30px;
    }
    .one-click-form{
      .form-field {
        width: inherit;
        float: none;
      }
      .form-hint {
        padding-left: 0px;
        width: inherit;
        font-size: 11px;
        color: #6d6861;
        max-width: 340px;
        padding-right: 0;
      }
      .form-submit {
        float: none;
        text-align: center;
      }
      p{
        margin-left: 0 !important;
        margin-bottom: 30px;
      }
      p br{
        display: none;
      }
    }
  }

}
