
/*   -=========== News-list ===========-   */

.news-text-container{
  margin-bottom: 80px;
}
.news-list{
  margin-bottom: 80px;
}
.news-announce{
  margin-bottom: 60px;

  a {
    text-decoration: none !important;
  }
  .news-pic{
    display: block;
    margin-bottom: 20px;

    img{
      width: 100%;
    }
  }
  .h2{
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .small-description{
    line-height: 1.5;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  time{
    color: #aaaaaa;
    padding-top: 8px;
  }
}

@media only screen and (max-width: 1024px) {
  .news-announce{
    .h2{
      flex-basis: 75%;
      max-width: 75%;
    }
    .small-description{
      flex-basis: 50%;
      max-width: 50%;
    }
  }

}
@media $mobile {
  .news-text-container{
    margin-bottom: 40px;
  }
  .news-list{
    margin-bottom: 40px;
  }
  .news-announce{
    margin-bottom: 40px;

    .h2{
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 8px;
    }
    .small-description{
      flex-basis: 100%;
      max-width: 100%;
    }
    time{
      padding-top: 0px;
    }
  }
}

.portfolio-gallery {
  text-align: center;
  margin-bottom: 45px;
  max-width: 100%;
  position: relative;

  @media only screen and (max-width: 1023px) {
    max-width: calc(100vw - 65px);
  }
  @media only screen and (max-width: 700px) {
    max-width: calc(100vw - 35px);
  }

  .slick-slide {
    margin-top: 0 !important;
  }

  .slick-slide img {
    margin: auto;
  }

  .slick-arrow {
    width: 40px; height: 40px;
    background: $black;
    font-size: 0;
    position: absolute;
    top: 50%;
    z-index: 1000;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity .25s ease;

    &:before {
      content: '';
      display: block;
      width: 12px; height: @width;
      border-top: 2px solid $white;
      position: absolute;
      top: 50%;
      margin-top: -6px;
    }
  }

  .slick-slider:hover .slick-arrow {
    opacity: .5;

    &:hover {
      opacity: 1;
    }

    &.slick-disabled {
      opacity: .2;
    }
  }

  .slick-prev {
    left: 15px;

    &:before {
      left: 50%;
      border-left: 2px solid $white;
      transform: rotate(-45deg);
      margin-left: -3px;
    }
  }

  .slick-next {
    right: 15px;

    &:before {
      right: 50%;
      border-right: 2px solid $white;
      transform: rotate(45deg);
      margin-right: -3px;
    }
  }
}

.portfilio-thumbs {
  margin-top: 5px !important;
  position: relative;

  @media only screen and (max-width: 1023px) {
    display: none;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    font-size: 0;
    width: 30px; height: calc(100% - 8px);
    z-index: 5;
    background: $white;
    opacity: 1;

    &:before {
      content: '';
      display: block;
      width: 8px; height: @width;
      border-top: 3px solid $gray;
      position: absolute;
      top: 50%;
      transition: all .25s ease;
    }
  }

  .slick-prev {
    left: auto; right: 100%;

    &:before {
      left: 50%;
      border-left: 3px solid $gray;
      transform: rotate(-45deg) translate(-50%, -50%);
      transform-origin: left top;
      margin-left: 4px;
    }
  }

  .slick-next {
    left: 100%;

    &:before {
      right: 50%;
      border-right: 3px solid $gray;
      transform: rotate(45deg) translate(0%, -100%);
      transform-origin: left top;
      margin-right: 2px;
    }
  }

  .slick-list {
    margin-left: -10px;
  }

  .item {
    position: relative;
    margin-top: 0 !important;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: all .25s ease;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      right: 0; top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 10px); height: calc(100% - 10px);
      object-fit: cover;
    }
  }

  .slick-current {
    opacity: .5;
  }

  .slick-track {
    min-width: 100%;
    display: flex;
    justify-content: center;
  }
}
