
/*   -=========== Fonts ===========-   */

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  src: url('/i/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
      url('/i/fonts/OpenSans-Light.woff') format('woff'),
      url('/i/fonts/OpenSans-Light.woff2') format('woff2'),
      url('/i/fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url('/i/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('/i/fonts/OpenSans-Regular.woff') format('woff'),
      url('/i/fonts/OpenSans-Regular.woff2') format('woff2'),
      url('/i/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url('/i/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('/i/fonts/OpenSans-SemiBold.woff') format('woff'),
      url('/i/fonts/OpenSans-SemiBold.woff2') format('woff2'),
      url('/i/fonts/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: url('/i/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('/i/fonts/OpenSans-Bold.woff') format('woff'),
      url('/i/fonts/OpenSans-Bold.woff2') format('woff2'),
      url('/i/fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  src: url('/i/fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('/i/fonts/OpenSans-ExtraBold.woff') format('woff'),
      url('/i/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
      url('/i/fonts/OpenSans-ExtraBold.ttf') format('truetype');
}