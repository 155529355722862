
/*   -=========== Errors ===========-   */

.error-page {
  &.error-404 {
    background: url(/i/404-bg.jpg) no-repeat 50% / cover;
  }

  .error-text {
    width: width('i/404.png'); height: height('i/404.png');
    max-width: 90vw; max-height: 90vh;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    background: url(/i/404.png) no-repeat 50% / contain;

    @media $small-handheld {
      background-position: 50% 0;
    }
  }

  .error-code {
    display: none;
  }

  .error-note {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -60%);
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: $semi-bold;
    font-variant: small-caps;
    text-transform: lowercase;
    color: $white;

    img {
      margin-bottom: 20px;
    }

    p + p {
      padding-top: 20px;
      margin-top: 20px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 31px; height: 2px;
        background: $white;
        position: absolute;
        top: -1px; left: 0;

        @media $small-handheld {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    @media $small-handheld {
      top: calc(50% + 20px);
      transform: translateX(-50%);
      background: rgba($black, .5);
      padding: 15px;
      width: 100%;
      text-align: center;
      font-style: 1.5rem;
      line-height: 1.2;
    }

    @media $landscape-mobile {
      @media $not-small-mobile {
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.3rem;
      }
    }

    @media $mobile {
      top: 30%;
    }
  }
}
