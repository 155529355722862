
/*   -=========== Product card ===========-   */

.product-card {
  display: flex;
  padding-bottom: 100px;
  min-height: 1200px;

  @media (max-width: 1500px) {
    min-height: 900px;
  }

  @media $small-notebook-and-less {
    min-height: 830px;
  }

  @media $handheld {
    padding-bottom: 70px;
  }

  @media $mobile {
    flex-direction: column;
  }

  @media $small-mobile {
    padding-bottom: 0;
  }

  @media print {
    .product-card--content,
    & ~ .group,
    & ~ .product-card-others {
      display: none;
    }
  }

  &.hide--slider {
    .product-card--slider,
    .product-card--thumbs,
    .product-card--icons {
      display: none;
    }

    .product-card--frame-mirror {
      display: flex;
    }

    .product-card--frame,
    .product-card--frame__scale {
      display: block;
    }
  }
}

.product-card--image {
  line-height: 0;
  position: relative;
  z-index: 10;
  max-height: 960px;

  @media $not-small-handheld {
    margin-left: 15px;
  }

  @media $not-mobile {
    flex-basis: 50%;
    text-align: right;
    max-width: 50vw;
  }

  @media $mobile {
    max-width: 100vw;
  }

  .slick-slide img {
    display: inline-block;
  }

  .slick-dots {
    position: absolute;
    margin-top: -50px;
    width: auto; height: auto;
    left: auto; bottom: 5%; right: 5%;
    overflow: visible;
    opacity: 1;

    li {
      display: inline-block;
      list-style: none;
      width: 12px; height: @width;
      border-radius: 50%;
      margin: 0 7px;
      background: $black;
      opacity: .3;
      transition: opacity .25s ease;

      &.slick-active {
        opacity: 1;
      }
    }

    li button {
      width: 100%; height: 100%;
      font-size: 0;
      background: transparent;
    }
  }

  .slick-arrow {
    width: 40px; height: 40px;
    background: $black;
    font-size: 0;
    position: absolute;
    top: 50%;
    z-index: 1000;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity .25s ease;

    &:before {
      content: '';
      display: block;
      width: 12px; height: @width;
      border-top: 2px solid $white;
      position: absolute;
      top: 50%;
      margin-top: -6px;
    }
  }

  &:hover .slick-arrow {
    opacity: .5;

    &:hover {
      opacity: 1;
    }

    &.slick-disabled {
      opacity: .2;
    }
  }

  .slick-prev {
    left: 15px;

    &:before {
      left: 50%;
      border-left: 2px solid $white;
      transform: rotate(-45deg);
      margin-left: -3px;
    }
  }

  .slick-next {
    right: 15px;

    &:before {
      right: 50%;
      border-right: 2px solid $white;
      transform: rotate(45deg);
      margin-right: -3px;
    }
  }
}

.product-card--frame-restrictions {
  border: 2px solid #eb0c0c;
  padding: 20px;
  background-color: transparent;
  margin-top: -20px;
  margin-bottom: 25px;
  transition: all 150ms ease;
  transform: scaleY(1);
  transform-origin: 0 0;
  height: auto;
  opacity: 1;
  box-sizing: border-box;
  pointer-events: all;

  &.--hidden {
    pointer-events: none;
    height: 0;
    opacity: 0;
    margin: 0;
    transform: scaleY(0);
    transform-origin: 0 0;
    padding: 0;
  }
}

.product-card--wait-popup {
  background-color: $white;
  color: $black;
  position: relative;
  max-width: 25%;

  @media $handheld {
    max-width: 75%;
  }

  @media $small-mobile {
    max-width: 100%;
  }

  & > .close-popup {
    font-size: 0;
  }
}

.product-card--frame {
  width: 100%; // 700px;
  // height: 100%; // 450px;
  position: relative;
  display: none;
  overflow: hidden; //скрывает before для &__window
  max-height: 640px;

  @media $handheld {
    max-height: 575px;
  }

  @media $small-handheld {
    // height: 450px;
    max-height: 470px;
  }

  @media $small-mobile {
    max-height: 400px;
    overflow: hidden;
  }

  &.--panno {
    max-height: 100%;
    overflow: hidden;

    @media $handheld {
      max-height: 85%;
    }

    @media $small-handheld {
      height: 655px;
    }

    @media $small-mobile {
      height: 300px;
    }

    // & ~ .product-card--frame-mirror {
    //   margin-top: -25%;

    //   @media $notebook-and-less {
    //     margin-top: -40%;
    //   }

    //   @media $small-mobile {
    //     margin-top: 0;
    //   }
    // }

    .product-card--frame__window {
      max-height: 100%;

      @media $not-small-mobile {
        max-width: 100%;
      }
    }

    .product-card--frame__window::before {
      border-width: 90vw;
    }

    .product-card--frame__handle {
      background-repeat: no-repeat;
      max-height: fit-content;
      height: auto !important;

      > img {
        display: block;
        max-width: 850px;

        @media $handheld {
          max-height: 470px;
        }

        @media $small-handheld {
          max-height: 360px;
        }

        @media $mobile {
          height: 100%;
          // width: 100% !important;
        }

        // @media $small-mobile {
        //   max-height: 300px;
        // }
      }
    }
  }

  .product-card--frame__handle {
    width: 100%;
    cursor: move;
    max-height: 540px;
    // height: auto !important;
    position: relative;
    top: 50%;
    // background-size: contain; // для повторения
    // background-repeat: repeat-x;
    background-size: auto 33.33333%;
    background-repeat: repeat;

    // @media $small-mobile {
    //   width: 150%;
    // }

    @media $handheld {
      max-height: 470px;
    }

    @media $small-handheld {
      max-height: 360px;
    }

    @media $small-mobile {
      max-height: 300px;
    }

    > img {
      display: none;
    }

    &.mirrored {
      transform: scale(-1, 1) !important;
    }
  }

  &-mirror {
    display: none;
    border: 2px solid $black;
    // display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 240px;
    padding: 18px 0;
    margin-top: 25px;
    position: relative;
    font-weight: 700;
    font-size: 11px;
    outline: none;
    text-transform: uppercase;
    transition: all 100ms ease;
    background-color: $white;

    &:hover,
    &.highlighted {
      background-color: #f39406;
      border-color: #f39406;
    }

    i.icon {
      width: 20px;
      height: 16px;
      margin-right: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__window {
    border: 2px solid $black;
    position: absolute;
    top: 95px; left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 0;
    max-height: 540px;
    max-width: 820px;

    @media $notebook-and-less {
      max-width: 600px;
    }

    @media $handheld {
      max-height: 470px;
      max-width: 470px;
    }

    @media $small-handheld {
      max-width: 95%;
      max-height: 360px;
    }

    @media only screen and (max-width: 768px) {
      max-height: 100%;
    }

    @media $small-mobile {
      max-height: 300px;
    }

    &::before{
      content: '';
      width: calc(100% + 4px);
      height: @width;
      position: absolute;
      top: 50%; left 50%;
      transform: translate(-50%, -50%);
      display: block;
      border: 50vw solid $white;

      @media $small-mobile {
        border-width: 100vw;
      }
    }

    .product-card--frame__window-height,
    .product-card--frame__window-width {
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        border: 1px solid $black;
        margin: 0 auto;
        position: initial;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 11px;
        color: $gray;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: -3px;
        top: calc(50% - 1px);
        transform: rotate(45deg) translateY(-50%);
        width: 10px; height: 10px;
        background: transparent;
        border-left: 1px solid $black;
        border-bottom: 1px solid $black;
      }

      &::after {
        left: auto;
        right: 4px;
        border-left: 0;
        border-bottom: 0;
        border-right: 1px solid $black;
        border-top: 1px solid $black;

        @media $notebook-and-less {
          right: 5px;
        }
      }
    }

    .product-card--frame__window-width {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;

      &.inner {
        top: 5px;
      }

      &.--no-bg {
        min-width: 200px;

        &:before,
        &:after {
          left: 25px;
        }

        &:before {
          right: 32px;
          left: auto;
        }

        > div {
          border: 0;
        }
      }

      @media $small-mobile {
        width: 99%;
      }

      > div {
        width: 50%;
        height: 31px;
        min-width: 120px;

        b {
          margin-left: 5px;
          color: $black;
        }

        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: $black;
          position: absolute;
          z-index: -1;
          top: 50%;
        }
      }
    }

    .product-card--frame__window-height {
      position: absolute;
      left: -55px;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      display: flex;
      align-items: center;

      &.inner {
        left: 5px;
      }

      &.--no-bg {
        min-height: 200px;

        &:before,
        &:after {
          top: 30px;
          bottom: auto;
        }

        &:before {
          bottom: 25px;
          top: auto;
        }

        > div {
          border: 0;
        }
      }

      &::before {
        left: calc(50% - 1px);
        top: 5px;
        transform: rotate(45deg) translateX(-50%);
        border-left: 1px solid $black;
        border-top: 1px solid $black;
        border-bottom: 0;

        @media $notebook-and-less {
          left: calc(50% - 2px);
        }
      }

      &::after {
        right: calc(50% - 2px);
        border-top: 0;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        bottom: -2px;
        top: auto;

        @media $notebook-and-less {
          right: calc(50% - 1px);
        }
      }

      > div {
        height: 50%;
        width: 31px;
        min-height: 120px;

        b {
          margin-left: 5px;
          color: $black;
          display: flex;

          &::before {
            content: '|';
            color: $gray;
          }

          span {
            margin-left: 5px;
          }
        }

        aside {
          transform: rotate(-90deg);
          display: inline-flex;
        }

        &::before {
          content: '';
          height: 100%;
          width: 1px;
          background-color: $black;
          position: absolute;
          z-index: -1;
        }
      }
    }

    &:not(.suture--material) .product-card--frame__window-segments {
      display: none;
    }

    .product-card--frame__window-segments {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;

      section {
        // width: 20%;
        height: 100% !important;
        background: transparent;

        + section {
          border-left: 1px solid $white;
        }
      }
    }
  }

  &__scale {
    margin: 25px 0;
    display: none;

    &-caption {
      font-weight: 600;
      font-size: 16px;
      color: $gray;
      display: inline-block;

      @media $handheld {
        margin-bottom: 15px;

        br {
          display: none;
        }
      }
    }

    &-field {
      display: inline-flex;
      flex-direction: column;
      cursor: pointer;
      margin-left: 80px;

      @media $handheld {
        margin-left: 0;
        margin-right: 5vw;
      }

      @media $small-mobile {
        margin-right: 10vw;
      }

      &.checked img {
        filter: invert(100%) sepia(0%) saturate(1096%) hue-rotate(181deg) brightness(83%) contrast(83%);
      }

      > span {
        font-weight: 400;
        font-size: 15px;
        color: $gray;
      }

      > img {
        width: 54px;
        height: 70px;
        margin-bottom: 10px;
      }

      > input {
        display: none;
      }
    }
  }
}

.product-card--slider {
  white-space: nowrap;
  overflow: hidden;

  .item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: center;
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      width: 100%; height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0; top: 0;
    }
  }
}

.product-card--icons {
  position: absolute;
  right: 6%; top: 80px;
  z-index: 1000;

  @media $small-mobile {
    top: 25px;
  }

  .item {
    width: 63px; height: @width;
    background: $white;
    border-radius: 50%;
    position: relative;

    @media $small-mobile {
      width: 45px; height: @width;
    }
  }

  .item + .item {
    margin-top: 20px;

    @media $small-mobile {
      margin-top: 10px;
    }
  }
}

.product-card--article {
  font-size: 1.4rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  line-height: 1.35;
  text-align: center;

  @media $small-mobile {
    font-size: 1.2rem;
  }
}

.product-card--article-label {
  position: absolute;
  bottom: 50%; left: 50%;
  transform: translateX(-50%);
}

.product-card--article-value {
  position: absolute;
  top: 50%; left: 50%;
  transform: translateX(-50%);
  margin-top: -3px;
  white-space: nowrap;
}

.product-card--fav-link {
  display: none;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    margin-top: 2px;
    background: url(/i/fav-icon.png) no-repeat 50%;

    @media $small-mobile {
      background-size: 55% auto;
    }
  }
}

.product-card--content {
  flex: 1;
  max-width: ($wrapper-width / 2);
  // padding-left: 80px;
  padding-left: 40px;
  padding-bottom: 30px;
  box-sizing: border-box;
  z-index: 11;

  @media $tablet {
    padding-left: 40px;
    max-width: calc(50vw - 35px);
  }

  @media $mobile {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.product-card--type {
  font-size: 1.6rem;
  font-weight: normal;
  font-variant: small-caps;
  text-transform: lowercase;
  padding-top: 20px;
  margin-bottom: -5px;

  @media $tablet {
    padding-top: 10px;
  }
}

.product-card--name {
  margin-bottom: 65px;

  @media (max-width: 1480px) {
    margin-bottom: 0;
  }

  /* @media $handheld {
    margin-bottom: 30px;
  } */

  @media $mobile {
    display: none;
  }
}

.product-card--size {
  margin-top: -60px;
  margin-bottom: 30px;

  @media $handheld {
    margin-top: -25px;
    margin-bottom: 20px;
  }

  @media $mobile {
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: 1.3rem;
  }
}

.product-card--tabs {
  margin-bottom: 85px;

  .tabs-nav {
    margin-bottom: 40px;

    @media $small-handheld {
      li + li {
        margin-left: 15px;
      }

      a {
        font-size: 1.4rem;
      }
    }

    @media $small-mobile {
      a {
        font-size: 1.3rem;
      }
    }
  }

  @media $handheld {
    margin-bottom: 40px;
  }

  @media $mobile {
    margin-bottom: 20px;
  }
}

.product-card-postery {
  @media only screen and (min-width: 701px) and (max-width: 1150px) {
    .product-card--tabs {
      .tabs-nav{
        li{
          margin-left: 0px;
          display: flex;
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .product-card--tabs {
      .tabs-nav{
        li{
          margin-left: 0px;
          display: flex;
        }
      }
    }
  }
}


.product-card--selector {
  display: inline-block;
  min-width: 210px;
  text-align: center;

  .selectric-items li {
    padding-right: 38px;
  }

  @media $small-mobile {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.product-card--dimensions {
  display: flex;
  padding-top: 49px;

  @media (max-width: 1480px) {
    margin-bottom: 30px;
  }

  @media $less-than-wrapper {
    @media $desktop {
      margin-right: 10px;
    }
  }

  @media $small-handheld {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.product-card--frame__scale {
  > br {
    @media $desktop {
      display: none;
    }
  }
}

.offset-70 {
  margin-top: -70px;
}
.offset-35 {
  margin-top: -35px;
}

.product-card--dimensions-title {
  font-size: 1.6rem;
  font-weight: 600;
  display: block;
  font-style: italic;
  position: absolute;
  margin-bottom: 10px;
  white-space: nowrap;
}

.product-card--dimensions-caption,
.product-card--variants-caption {
  font-size: 1.6rem;
  font-weight: $semi-bold;
  min-width: 86px;
  position: relative;

  &.gray {
    color: #929292;
  }

  @media $small-handheld {
    text-align: center;
    width: 100%;

    br {
      display: none;
    }
  }

  @media $not-small-handheld {
    .product-card--dimensions-label {
      left: 0;
      transform: none;
    }
  }
}

.product-card--dimensions-field {
  position: relative;

  @media $not-small-handheld {
    margin-left: 45px;

    @media $tablet {
      margin-left: 15px;

      + .product-card--dimensions-field {
        margin-left: 45px;
      }
    }
  }

  @media $small-handheld {
    margin-top: 35px;
    min-width: 60%;
  }

  .inp {
    line-height: 43px;
    border: 3px solid $gray;
    padding: 0 20px;
    text-align: center;
  }

  + .product-card--dimensions-field {
    &:before {
      content: 'X';
      display: block;
      width: 20px; height: @width;
      line-height: @height;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
      position: absolute;
      top: 50%; right: 100%;
      transform: translateY(-50%);
      margin-right: 13px;

      @media $small-handheld {
        display: none;
        /* right: auto; left: 0;
        top: auto; bottom: 100%;
        transform: none;
        margin-bottom: 5px;
        margin-right: 0;
        width: 100%; */
      }
    }
  }
}

.product-card--dimensions-label {
  position: absolute;
  left: 50%; bottom: 100%;
  transform: translateX(-50%);
  margin-bottom: 10px;
  white-space: nowrap;
  font-size: 1.3rem;
  font-weight: normal;
  color: $gray;
}

.product-card--variants {
  display: flex;
  position: relative;

  @media $less-than-wrapper {
    @media $desktop {
      margin-right: 10px;
    }
  }

  @media $small-handheld {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media $not-small-handheld {
    .product-card--dimensions-label {
      left: 0;
      transform: none;
    }
  }

  .product-card--dimensions + & {
    margin-top: 30px;
  }

  + .product-card--variants {
    margin-top: 30px;
  }
}

.product-card--variants-caption {
  margin-bottom: 10px;
}

.product-card--variants-list {
  display: flex;
  flex: 1;

  @media $not-small-handheld {
    .product-card--variants-caption + & {
      margin-left: 45px;

      @media $tablet {
        margin-left: 15px;
      }
    }
  }

  .item {
    flex: 1;
    max-width: 100px;
    position: relative;
    box-shadow: 0 0 5px rgba($black, .22);
    transition: box-shadow .25s ease;

    .product-card--variants_with-icons & {
      box-shadow: none;

      &:before {
        box-shadow: 0 0 5px rgba($black, .22);
        transition: box-shadow .25s ease;
        z-index: 10;
      }

      &.is-active {
        box-shadow: none;

        &:before {
          box-shadow: inset 0 0 0 2px $black;
        }

        &:after {
          opacity: 0;
        }
      }
    }

    &:not(.is-active),
    &[data-optional] {
      cursor: pointer;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
      position: relative;
    }

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      box-sizing: border-box;
      border: 2px solid $black;
      position: absolute;
      left: 0; top: 0;
      opacity: 0;
      transition: opacity .25s ease;
    }

    &.is-active {
      box-shadow: 0 0 0 $black;

      &:after {
        opacity: 1;
      }
    }
  }

  .item + .item {
    margin-left: 15px;

    @media $handheld {
      margin-left: 8px;
    }
  }

  .item_variant-1:before {
    background: url(/i/wall-variant-1.jpg) no-repeat 50% / cover;
  }

  .item_variant-2:before {
    background: url(/i/wall-variant-2.jpg) no-repeat 50% / cover;
  }

  .item_variant-3:before {
    background: url(/i/wall-variant-3.jpg) no-repeat 50% / cover;
  }

  .item_variant-4:before {
    background: url(/i/wall-variant-4.jpg) no-repeat 50% / cover;
  }

  .item_variant-41:before {
    background: url(/i/wall-variant-41.jpg) no-repeat 50%;
  }

  .item_variant-42:before {
    background: url(/i/wall-variant-42.jpg) no-repeat 50%;
  }

  .item_variant-43:before {
    background: url(/i/wall-variant-43.jpg) no-repeat 50%;
  }

  .item_variant-44:before {
    background: url(/i/wall-variant-44.jpg) no-repeat 50%;
  }

  .item_variant-60:before {
    background: url(/i/wood-variant-60.jpg) no-repeat 50% / cover;
  }

  .item_variant-61:before {
    background: url(/i/wood-variant-61.jpg) no-repeat 50% / cover;
  }

  .item_variant-62:before {
    background: url(/i/wood-variant-62.jpg) no-repeat 50% / cover;
  }

  .item--icon {
    width: 100%;
    padding-bottom: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  .item--caption {
    position: absolute;
    bottom: 50%; left: 50%;
    transform: translateX(-50%);
    font-size: 1.6rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    text-align: center;
    line-height: 1;
    margin-bottom: 5px;

    .product-card--variants_with-icons & {
      position: static;
      transform: none;
    }

    @media $handheld {
      font-size: 1.4rem;
    }

    @media $small-mobile {
      font-size: 1.2rem;
    }
  }

  .item--price {
    position: absolute;
    left: 50%; top: 50%;
    transform: translateX(-50%);
    font-size: 1.4rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    text-align: center;
    white-space: nowrap;
    margin-top: 5px;

    .product-card--variants_with-icons & {
      position: static;
      transform: none;
      margin-top: 5px;
    }

    @media $handheld {
      font-size: 1.3rem;
    }

    @media $small-mobile {
      font-size: 1.2rem;
    }
  }

  .item--meters {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    text-align: center;
    white-space: nowrap;
    margin-top: 21px;
    color: #333;

    .small {
      font-weight: medium;
      font-size: 1.0rem;
    }
  }

  .item--preview-button,
  .item--hint-button {
    width: 16px; height: @width;
    line-height: @width;
    text-align: center;
    background: url(/i/zoom-icon.png) no-repeat 50%;
    position: absolute;
    right: 5px; top: 5px;
    z-index: 5;
    opacity: 0;
    transition: opacity .25s ease;

    @media $handheld {
      display: none;
    }
  }

  .item--hint-button {
    background: none;

    &:before {
      content: '?';
      color: $black;
      font-weight: bold;
      font-size: 2rem;
    }
  }

  .item:hover .item--preview-button,
  .item:hover .item--hint-button {
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }

  .item--preview,
  .item--hint {
    display: none;
    width: 300px; height: 300px;
    position: absolute;
    right: 0; bottom: 100%;
    margin-bottom: 6px;
    margin-right: -7px;
    border: 2px solid rgba($black, .5);
  }

  .item--hint {
    width: auto; height: auto;
    padding: 5px;
  }

  .item--text {
    position: absolute;
    bottom: auto;
    top: 100%; right: auto; left: 50%;
    transform: translateX(-50%);
    margin-bottom: auto;
    margin-top: 6px;
    width: auto; height: auto;
    background: $white;
    white-space: nowrap;
    border: none;
    font-size: 1.1rem;
  }
}

.product-card--extra-params {
  margin-top: 35px;

  .product-card--variants {
    flex-wrap: wrap;
  }

  .product-card--variants-caption {
    width: 100%;
  }

  .product-card--variants-list {
    margin-left: 0;
    flex-basis: 100%;
    padding-bottom: 0;

    @media $small-handheld {
      flex-wrap: wrap;
    }

    @media $small-mobile {
      .item {
        min-width: 86px;
        max-width: 86px;
        margin: 5px;
      }
    }

    .item {
      max-width: 75px;

      @media $small-handheld {
        flex: inherit;

        @media $not-small-mobile {
          margin-bottom: 8px;
          margin-right: 8px;

          + .item {
            margin-left: 0;
          }
        }
      }
    }

    .item--caption {
      font-size: 1.2rem;
    }
  }
}

.product-card--extra-params-toggler {
  display: inline-block;
  font-weight: bold;
  position: relative;
  padding-right: 20px;
  line-height: 1.1;
  border-bottom: 1px dashed rgba($black, .5);
  cursor: pointer;
  transition: border-bottom-color .25s ease;

  &:hover {
    border-bottom-color: $black;
  }

  &:before, &:after {
    content: '';
    display: block;
    background: $black;
    position: absolute;
  }

  &:before {
    width: 13px; height: 3px;
    right: 0; top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    width: 3px; height: 13px;
    right: 5px; top: 50%;
    transform: translateY(-50%);
    transition: all .25s ease;
  }
}

.product-card--extra-params-body {
  overflow: hidden;
  padding: 0 5px;
  max-height: 0;
  opacity: 0;
  transition: all .25s ease;

  @media $small-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

.product-card--extra-params.is-expanded {
  .product-card--extra-params-body {
    max-height: 1500px;
    opacity: 1;
    padding: 20px 5px;

    @media $small-mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .product-card--extra-params-toggler:after {
    height: 0;
    opacity: 0;
  }
}

.product-card--description {
  line-height: 1.35;
}

.product-card--payment {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  font-size: 14px;

  @media $mobile {
    display: block;
    text-align: center;
  }

  span {
    font-weight: bold;
  }

  .btn--hint-icon {
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 5px;
  }
}

// .payment--hint {

// }

.product-card--price {
  display: flex;
  margin-bottom: 10px;

  .price {
    @extend .h1;
    font-weight: $semi-bold;
    color: $black;

    .small {
      font-size: 18px;
      font-weight: 700;

      @media only screen and (max-width: 700px){
        font-size: 13px;
      }
    }
  }

  .old-price {
    @extend .h1;
    font-weight: $light;
    text-decoration: line-through;
    color: $light-gray;
    margin-left: 9px;
    margin-top: 6px;
    font-size: 13px;

    &:before {
      content: '/';
      display: inline-block;
      margin-right: 9px;
    }
  }

  @media $mobile {
    margin-bottom: 20px;
    align-self: center;
  }
}

@media only screen and (min-width: 700px){

  .product-card--price {
    display: flex;
    flex-direction: column;

    .old-price{
      font-size: 16px;
      font-weight: 500;
      margin-left: 0;
      order: -1;

      &:before{
        content: '';
        margin-right: 0;
      }
    }
  }
}

.product-card--controls {
  display: inline-flex;
  position: relative;

  @media $small-handheld {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media $mobile {
    align-items: center;
  }
}

.product-card--controls + .product-card--controls {
  margin-top: 17px;

  @media $small-handheld {
    &:not(.product-card--controls_no-texture-link) {
      margin-top: -115px;
      margin-bottom: 49px;
    }
  }
}

.product-card--controls-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  & + & {
    @media $not-small-handheld {
      margin-left: 20px;
    }

    @media $small-handheld {
      &.design-tile{
        margin-top: 10px;
      }

      &:not(.design-tile) {
        margin-top: 83px;
      }
    }
  }
}

.product-card--buy-btn {
  @extend .btn, .orange-btn, .h49btn;
  width: 210px;
  font-size: 1.5rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;

  @media $small-handheld {
    width: 268px;
  }
}

.product-card--texture-link {
  @extend .btn, .h49btn;
  width: 210px;
  font-size: 1.5rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  color: $black;
  box-shadow: inset 0 0 0 2px $black;
  background: transparent;

  &:hover {
    background-color: $black;
    color: $white;
  }

  @media $small-handheld {
    width: 268px;
  }
}

.product-card--sample-btn {
  @extend .btn, .black-bordered-btn, .h49btn;
  // width: 210px;
  width: 440px;
  max-width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;

  @media $small-handheld {
    width: 268px;
  }

  // @media $not-small-handheld {
  //   position: absolute;
  //   left: 100%; top: 0;
  //   margin-left: 20px;
  // }
}

.product-card--sizes-btn {
  @extend .btn, .black-bordered-btn, .h49btn;
  width: 210px;
  font-size: 1.6rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
}

.product-card--fast-order-link {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: normal;
  color: #3a3a3a;
  margin-top: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(#3a3a3a, .5);
  transition: border-bottom-color .25s ease;

  &:hover {
    border-bottom-color: #3a3a3a;
  }
}

.product-card--params-table {
  font-size: 1.6rem;

  @media $small-handheld {
    font-size: 1.4rem;
  }

  th, td {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  th {
    text-align: left;
    font-weight: $semi-bold;
    white-space: nowrap;
  }

  td {
    padding-left: 40px;
    font-weight: normal;
    width: 100%;
  }
}

.product-card--features {
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;

  .feature {
    text-align: center;
  }

  @media only screen and (min-width: 1025px) {
    .feature + .feature {
      margin-left: 45px;

      @media $small-mobile {
        margin-left: 40px;
      }
    }
  }

  .feature--icon {
    line-height: 80px;
    margin-bottom: 10px;

    img {
      vertical-align: middle;
    }
  }

  .feature--caption {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;

    @media $handheld {
      font-size: 1.2rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;

    .feature {
      width: calc(50%);
      margin: 10px 0;
    }
  }

  @media $mobile {
    order: 1;
  }
}


.product-card--info-section {
  margin-top: 60px;
  margin-bottom: 60px;
}

.product-card--info-caption {
  font-weight: bold;
  margin-bottom: 1em;
  padding-bottom: .5em;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 31px; height: 2px;
    background: $black;
    position: absolute;
    left: 0; bottom: 0;
  }
}

.product-card--info-content {
  @media $not-small-handheld {
    display: flex;
    justify-content: space-between;

    .text-container {
      flex: 2;

      > * {
        max-width: 600px;
      }
    }

    .product-card--info-params {
      flex: 1;
    }

    .text-container + .product-card--info-params {
      margin-left: 45px;
    }
  }
}

@media $small-handheld {
  .text-container + .product-card--info-params {
    margin-top: 2em;
  }
}

.product-card--info-params {
  .item {
    font-size: 1.5rem;
  }

  .item + .item {
    margin-top: .5em;
  }

  .item--label {
    font-weight: bold;
  }
}


// Features section

.product-card-shop-features {
  background: $almost-white;

  .wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 115px;
    padding-bottom: 100px;

    @media $small-handheld {
      flex-wrap: wrap;
    }

    @media $mobile {
      padding-top: 40px;
      padding-bottom: 30px;
    }
  }

  .feature {
    @media $small-handheld {
      flex-basis: calc(50% - 10px);

      &:nth-child(2) ~ .feature {
        margin-top: 60px;
      }
    }

    @media $small-mobile {
      flex-basis: 100%;

      &:first-child ~ .feature {
        margin-top: 40px;
      }
    }
  }

  .feature + .feature {
    margin-left: 20px;

    @media $small-handheld {
      margin-left: 0;
    }
  }

  .feature--caption-line {
    position: relative;
    margin-bottom: 20px;
  }

  .feature--caption-line,
  .feature--text,
  .feature--link {
    padding-left: 70px;
  }

  .feature--number {
    position: absolute;
    left: 0; bottom: -5px;
    font-size: 4.8rem;
    font-weight: bold;

    @media $mobile {
      font-size: 3.5rem;
      bottom: -3px;
    }
  }

  .feature--caption {
    font-size: 2.4rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;

    @media $mobile {
      @media $not-small-mobile {
        font-size: 2rem;
      }
    }

    @media $small-mobile {
      font-size: 2.3rem;
    }
  }

  .feature--text {
    font-size: 1.6rem;
    line-height: 1.75;
    color: $dark;
    min-height: 56px;
    margin-bottom: 40px;


    @media $small-handheld {
      margin-bottom: 20px;
    }

    @media $mobile {
      font-size: 1.4rem;
    }

    a {
      text-decoration: none;
      display: inline-block;
      line-height: 1;
      border-bottom: 1px solid rgba($dark, .5);
    }
  }

  .feature--link .a,
  .feature--link a {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    padding-right: 40px;
    text-decoration: none;
    position: relative;

    @media $mobile {
      font-size: 1.4rem;
    }

    &:after {
      content: '';
      display: block;
      width: 20px; height: 20px;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      position: absolute;
      right: 0; top: 50%;
      transform: rotate(45deg) translateX(-50%);
      margin-top: -2px;
    }
  }
}


.product-card--thumbs {
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;

  @media $small-handheld {
    padding-left: 30px;
    padding-right: 30px;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    font-size: 0;
    width: 30px; height: calc(100% - 8px);
    z-index: 5;
    background: $white;
    opacity: 1;

    &:before {
      content: '';
      display: block;
      width: 8px; height: @width;
      border-top: 3px solid $gray;
      position: absolute;
      top: 50%;
      transition: all .25s ease;
      margin-top: -2px;
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      left: 50%;
      border-left: 3px solid $gray;
      transform: rotate(-45deg) translate(-50%, -50%);
      transform-origin: left top;
      margin-left: 4px;
    }
  }

  .slick-next {
    right: 0;

    &:before {
      right: 50%;
      border-right: 3px solid $gray;
      transform: rotate(45deg) translate(0%, -100%);
      transform-origin: left top;
      margin-right: 2px;
    }
  }

  .slick-list {
    margin-left: -10px;
  }

  .item {
    position: relative;
    margin-top: 0 !important;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: all .25s ease;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      right: 0; top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 10px); height: calc(100% - 10px);
      object-fit: cover;
    }
  }

  .slick-current {
    opacity: .5;
  }

  .slick-track {
    min-width: 100%;
  }
}


// Others section

.product-card-others {
  padding-top: 95px;

  @media $mobile {
    padding-top: 50px;
  }
}

.product-card-others--caption {
  @extend .caption;
  text-align: center;
  margin-bottom: 70px;

  @media $mobile {
    margin-bottom: 40px;
  }

  &:after {
    left: 50%;
    transform: translateX(-50%);
  }
}


.moving-to-basket {
  transition:all .75s ease;
  z-index: 10000;
}


.material-item {
  // min-height: calc(25vw - 140px);
  overflow: hidden;

  @media (max-width: 1720px) {
    font-size: 14px;
  }

  @media (max-width: 1340px) {
    font-size: 12px;
  }

  @media (max-width: 1280px) {
    margin-bottom: 45px;
  }
}

.material-tab--img {
  float: left;
  // width: 40%;
  width: calc(25vw - 183px);
  height: @width;
  margin-top: -8px !important;
  margin-right: 10px;
  margin-bottom: 10px;
  object-fit: cover;

  @media (max-width: 1720px) {
    width: calc(25vw - 180px);
    height: @width;
  }

  @media (max-width: 1480px) {
    width: calc(25vw - 147px);
    height: @width;
  }

  @media (max-width: 1340px) {
    width: calc(25vw - 143px);
    height: @width;
  }
}
.material-tab--ul {
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 1720px) {
    font-size: 11px;
  }

  @media (max-width: 1620px) {
    @media (min-width: 1281px) {
      margin-bottom: 10px;

      li + li {
        margin-top: .5em !important;
      }
    }
  }
}
.material-tab--p {
  // margin-left: calc(40% + 15px);
  margin-left: calc(25vw - 183px + 15px);

  @media (max-width: 1720px) {
    margin-left: calc(25vw - 180px + 15px);
  }

  @media (max-width: 1480px) {
    margin-left: calc(25vw - 147px + 15px);
  }

  @media (max-width: 1340px) {
    margin-left: calc(25vw - 143px + 15px);
  }
}

@media only screen and (max-width: 1280px) and (min-width: 701px){
  .material-tab--img {
    float: none;
    width: 90%;
    min-height: 194px;
    height: 194px;
  }
  .material-tab--p {
    margin-left: 0;
  }
}

@media only screen and (max-width: 480px){
  .material-tab--img {
    float: none;
    width: 90%;
    min-height: 194px;
    height: 194px;
  }
  .material-tab--p {
    margin-left: 0;
  }
}


.btn--hint-icon {
  @extend .btn;
  vertical-align: middle;
  width: 16px; height: @width;
  line-height: @width;
  text-align: center;

  &:before {
    content: '?';
    color: $black;
    font-weight: bold;
    font-size: 2rem;
  }

  @media $handheld {
    display: none;
  }
}

.product-card--sample-btn + .btn--hint-icon {
  position: absolute;
  left: 100%; top: 0;
  // margin-left: 235px;
  margin-left: 5px;
}

.btn--hint {
  display: none;
  width: 300px;
  position: absolute;
  left: 100%; top: 0;
  margin-left: 5px;
  border: 2px solid rgba($black, .5);
  background-color: $white;
  padding: 10px;
  text-align: left;
  font-size: 1.2rem;

  @media only screen and (max-width: 1850px) {
    left: auto; right: 100%;
    margin-left: 0;
    margin-right: 5px;
  }
}

.btn--hint-icon:hover .btn--hint {
  display: block;
}


// Spoilers

.text-section {
  border-top: 1px solid #c4c4c4;
  margin: 0 40px;

  @media only screen and (max-width: 480px) {
    margin: 0 15px;
  }
}

.text-section:first-child {
  margin-top: 35px;
}

.text-section:last-child {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 35px;
}

.text-section__caption {
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .01em;
  padding: 15px 0 17px;
  cursor: pointer;

  @media $small-mobile {
    font-size: 1.4rem;
    padding-top: 10px;
    padding-bottom: 12px;
  }

  .wrapper {
    position: relative;
  }

  .icon {
    display: block;
    position: absolute;
    right: 100%; top: 50%;
    transform: translateY(-50%);
    width: 40px; height: 40px;
    margin-top: 1px;
    transition: all .25s ease;

    @media $small-mobile {
      margin-top: -1px;
    }

    &:before, &:after {
      content: '';
      display: block;
      background: $black;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 17px; height: 3px;

      @media $small-mobile {
        width: 12px; height: 2px;
      }
    }

    &:after {
      width: 3px; height: 17px;

      @media $small-mobile {
        width: 2px; height: 12px;
      }
    }
  }
}

.text-section__body {
  font-size: 1.6rem;
  line-height: 1.35;
  overflow: hidden;
  max-height: 0;
  transition: all .25s ease;

  @media $small-mobile {
    font-size: 1.4rem;
  }
}

.text-section.is-expanded {
  .text-section__caption .icon {
    transform: translateY(-50%) rotate(-45deg);
  }

  .text-section__body {
    max-height: var(--max-height);
    padding-bottom: 50px;
  }
}

.product-card--materials-caption {
  font-size: 1.6rem;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 20px;
  cursor: pointer;

  @media $small-mobile {
    text-align: center;
  }

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 10px; height: 10px;
    border-top: 3px solid #929292;
    border-right: @border-top;
    transform: rotate(-45deg);
    margin-left: 25px;
    margin-top: 6px;

    @media $small-mobile {
      margin-left: 10px;
    }
  }
}

.product-card--materials-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media $mobile {
    gap: 15px;
    grid-template-columns: 1fr;
  }
}

.product-card > div.product-card__h1-mobile {
  display: none;

  @media $mobile {
    display: block;
    padding: 20px 10px;
  }
}

// .product-card--materials-list-item + .product-card--materials-list-item {
//   margin-top: 30px;
// }

.product-card--materials-container {
  display: flex;

  .btn--hint-icon {
    padding: 5px;
    position: relative;

    @media $handheld {
      display: inline-flex;
      justify-content: center;
    }

    @media $small-mobile {
      position: absolute;
      right: 15px;
    }
  }

  .btn--hint {
    left: auto;
    right: 100%;
    margin-right: 4px;
    z-index: 1;

    ul {
      padding-left: 1.5em;
    }

    ul + p,
    p + p {
      margin-top: 1em;
    }
  }
}

.product-card--materials-list-item {
  width: 100%;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1386px) {
    padding-right: 20px;
  }

  @media $handheld {
    padding-right: 0;
  }

  &:not(.checked) {
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.product-card--materials-list-image-container {
  margin-bottom: 10px;
  position: relative;
  line-height: 0;
}

.product-card--materials-list-item.checked .product-card--materials-list-image-container:after {
  content: '';
  display: block;
  width: 100%; height: 100%;
  box-shadow: inset 0 0 0 2px #f39406;
  position: absolute;
  left: 0; top: 0;
}

.product-card--materials-list-image {
  width: 100%;
  /* height: 173px; */
  height: 120px;
  object-fit: cover;

  @media $mobile {
    height: 75px;
  }
}

.product-card--materials-list-text {
  font-size: 1.4rem;
}

.product-card--materials-list-description {
  font-size: 1.2rem;
  margin-top: 0.25em;

  @media $handheld {
    display: none;
  }
}

.product-card--materials-body {
  transition: all .25s ease;
}

.product-card--materials.is-collapsed {
  margin-bottom: -30px;

  .product-card--materials-caption:after {
    transform: rotate(135deg);
    margin-top: -7px;
  }

  .product-card--materials-body {
    max-height: 0;
    opacity: 0;
  }
}

.product-card.product-card-dizajnerskaya_plitka {
  .product-card--materials-list {
    display: flex;
    flex-direction: column;

    @media $not-small-handheld {
      max-width: 70%;
    }

    @media $small-mobile {
      font-size: 14px;
    }

    .radio > input {
      display: none;
    }

    .radio + .radio {
      margin-top: 35px;
    }
  }

  dl {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    dt, dd {
      background-color: $white;
      display: inline-block;
      position: relative;
      z-index: 1;
    }

    dt {
      padding-right: 5px
    }

    dd {
      padding-left: 5px;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      bottom: 1px; left: 0;
      border-bottom: 1px dashed $black;
      width: 100%;
    }
  }
}
