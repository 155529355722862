
/*   -=========== Catalogue ===========-   */

.catalogue--caption {
  margin-bottom: 20px;
}

.catalogue--text {
  line-height: 1.5;
  margin-bottom: 30px;

  @media only screen and (max-width: 568px) {
    order: 2;
  }
}

.catalogue--sorting {
  margin-bottom: 35px;
  line-height: 0;
  text-align: right;

  .item {
    display: inline-block;
    min-width: 262px;
    text-align: center;
  }

  .item .selectric-items li {
    padding-right: 38px;
  }
}

.painter-photo {
  float: left;
  margin-right: 15px;
  width: 170px;

  @media $small-mobile {
    width: auto;
    margin-right: 0;
    margin-bottom: 1em;
  }
}


@media only screen and (min-width: 1401px){
  .ias-trigger-prev {
    position: relative !important;
    float: right;
    margin-top: -60px;
  }
}

.catalogue--posters-block {
  display: flex;
  width: 600px;
  height: 72px;
  box-sizing: border-box;
  border: 2px solid #e8e8e8;

  @media only screen and (min-width: 1401px) {
    position: relative;
    margin-top: -15px;
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 1400px) and (min-width: 1024px) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%;
  }
  @media only screen and (max-width: 568px) {
    margin-bottom: 15px;
    width: 264px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    order: 1;
  }

  &__icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #e8e8e8;
    width: 70px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;

    img {
      width: 24px;
      height: 24px;
    }

    @media only screen and (max-width: 568px) {
      width: 100%;
      height: 38px;
      justify-content: center;
      padding-left: 18px;
      padding-right: 18px;


      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  &__text {
    padding: 15px 15px 15px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.45;

    @media only screen and (max-width: 568px) {
      padding: 8px 15px 8px 15px;

      br {
        display: none;
      }
    }
  }
}

#butt-text {
  margin-bottom: 70px;

  @media $small-mobile {
    margin-bottom: 50px;
  }
}
