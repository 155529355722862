.ymaps-map {
  position:relative; 
  width: 100%; 
  height: 1000px; 

  @media $mobile {
    height: auto;
  }

  ymaps {
    @media $mobile {
      display: none;
    }
  }
}

.dealers__title {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  position: relative;

  @media $mobile {
    font-size: 25px;
    margin-bottom: 35px
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 50px;
    height 3px;
    background-color: $black;

    @media $mobile {
      bottom: -5px;
    }
  }
}

.dealers-aside {
  box-sizing: border-box;
  width: 400px;
  height: 650px;
  max-height: 650px;
  position: absolute;
  left: 10%;
  top: 5%;
  z-index: 999;
  background-color: $white;
  border: 2px solid #D5D8DC;

  @media only screen and (max-width: 1440px) {
    left: 2%;
  }

  @media $mobile {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin-bottom: 85px;
  }
}

.dealers-aside__inner {
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-width: none; //for FireFox
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  @supports (overflow: overlay) {
    overflow: overlay;
  }
}

.dealers-aside__header {
  padding: 17px 25px;
  background-color: $black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  z-index: 600;
  top: 0;

  &-country,
  &-city {
    box-sizing: border-box;
    max-width: 50%;
    width: 40%;

    .selectric {
      background: $black;
      border: 0;
      border-bottom: 2px solid $dark;

      .label {
        height: 25px;
        line-height: 25px;  
        margin: 0;
        margin-right: 38px;
        font-weight: bold;
        font-size: 14px;
        font-feature-settings: 'liga' off;
        color: $white;
      }

      .button {
        width: 25px;
        height: 25px;
      }

      .button::before {
        right: 0;
        left: auto;
        border-bottom: 2px solid $dark;
        border-left: 2px solid $dark;
      }
    }
    
    .selectric-wrapper.selectric-open {
      .button::before {
        transform: rotate(135deg) translateY(-50%);
        right: 5px;
      }
    }

    .selectric-items {
      width: 160px !important;
      background: $white;

      li {
        transition: all 100ms ease;
        font-weight: normal;
        font-size: 14px;
        font-feature-settings: 'liga' off;
        color: $black;
      }

      li.highlighted,
      li.selected,
      li:hover {
        background: $white;
        font-weight: bold;
      }
    }
  }

  label {
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    font-feature-settings: 'liga' off;
    color: $white;
    margin-bottom: 5px;
  }
}

.dealers-aside__item {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  cursor: pointer;
  transition: all 180ms linear;
  position: relative;

  @media $mobile {
    &.is-open::after {
      transform: rotateZ(-45deg) translateY(15%);
      right: 22px;
    }

    &.is-open + .dealers-info__inner {
      display: flex;
    }
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    box-sizing: border-box;
    width: 8px;
    height: @width;
    border-top: 2px solid $black;
    border-right: @border-top;
    top: 50%;
    right: 20px;
    transform: rotateZ(45deg) translateY(-50%);
    
    @media $mobile {
      transform: rotateZ(135deg) translateY(15%);
    }
  }
  
  &:hover, 
  &:focus, 
  &:active {
    background-color: $black;

    &::after,
    .dealers-aside__item-title, 
    .dealers-aside__item-address {
      color: $white;
      border-color: $white;
    }
  }

  &:not(:first-child) {
    border-top: 2px solid #D5D8DC;
  }

  &-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    font-feature-settings: 'liga' off;
    margin-bottom: 5px;
    transition: all 180ms linear;
  }

  &-address {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'liga' off;
    color: $black;
    transition: all 180ms linear;
  }
}

.dealers-info {
  position: absolute;
  opacity: 0;
  display: none;
  left: 105%;
  top: 0;
  z-index: 998;
  background-color: $white;
  border: 2px solid #D5D8DC;
  width: 440px;
  height: 260px;
  min-height: 250px;
  transition: all 300ms ease;
  overflow-y: auto;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    width: 0;
  }

  @media only screen and (max-width: 900px) {
    left: 430px;
    width: 396px;
    top: 13%;
  }

  @media $small-handheld {
    left: 15%;
  }

  @media $mobile {
    left: 2%;
  }

  &.is-active {
    display: block;
    opacity: 1;
    left: 105%;

    @media only screen and (max-width: 1440px) {
      left: 450px;
    }

    @media $handheld {
      left: 430px;
    }

    @media only screen and (max-width: 900px) {
      z-index: 999;
      left: 0;
    }

    @media $mobile {
      display: none;
    }
  }

  &__inner {
    padding: 15px 17px;
    display: flex;
    flex-direction: row;
    height: 100%;
    box-sizing: border-box;

    &.info__spoiler {
      display: none;
    }

    @media $mobile {
      display: none;
    }
  }

  &__item {
    width: 50%;
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      font-feature-settings: 'liga' off;
      margin-bottom: 5px;
      color: $black;
    }

    &-address {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      font-feature-settings: 'liga' off;
      color: $black;
      margin-bottom: 20px;
    }

    &-time {
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: $black;
      margin-bottom: 20px;
    }

    span {
      font-weight: 300;
      font-size: 11px;
      line-height: 15px;
      font-feature-settings: 'liga' off;
      color: $black;
    }

    &-phone,
    &-site,
    &-mail {
      font-weight: bold;
      font-size: 12px;
      line-height: 173.68%;
      font-feature-settings: 'liga' off;
      color: $black;
      text-decoration: none;
    }
  }

  &__img {
    width: 50%;
    max-height: 100px;
    // background: url('/i/dealers-logo.svg') 50% 0/cover no-repeat; 

    @media $mobile {
      max-height: none;
    }

    @media $small-mobile {
      display: none;
    }
  }

  &__close {
    position: absolute;
    right: 15px;
    top: 12px; 
    width: 12px;
    height: @width;
    background: url('/i/dealers-close.svg') 50% 0/cover no-repeat; 
  }
}