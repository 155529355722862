.blog-page {
  padding-bottom: 130px;
}

.blog-page__body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 100px;

  @media $handheld {
    grid-template-columns: repeat(2, 1fr);
  }

  @media $small-mobile {
    grid-template-columns: repeat(1, 1fr);
  }
}

.blog-page__item {
  text-decoration: none;
  border-bottom: 1px solid $black;

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;

    @media $small-handheld {
      height: 200px;
    }
  }
}

.blog-page__item-date {
  display: block;
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0;

  @media $small-mobile {
    margin: 5px 0;
    font-size: 12px;
  }
}

.blog-page__item-name {
  display: block;
  font-weight: 700;
  font-size: 18px;
  min-height: 80px;

  @media $small-mobile {
    font-size: 16px;
  }
}