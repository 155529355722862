
/*   -=========== Instagram widget ===========-   */

.instagram-section {
  padding-top: 35px;
  padding-bottom: 50px;
}

.instagram-section__caption {
  @extend .h1;
  text-align: center;
  padding: 0 20px;
  margin-bottom: .5em;

  a {
    text-decoration: none;
  }
}

.instawidget-container {
  overflow: hidden;
}

.instawidget-wrapper {
  position: relative;
}

.instawidget {
  position: relative;
  white-space: nowrap;
  font-size: 0;
  left: 0;
  transition: left .3s ease;
  line-height: 0;
}

.instawidget-page {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  white-space: normal;
  opacity: .25;

  &.is-active {
    opacity: 1;
  }

  img {
    object-fit: cover;
    height: 16.66vw;
    width: 16.66%;

    @media $handheld {
      height: 25vw;
      width: 25%;
    }

    @media $small-mobile {
      height: 33.33vw;
      width: 33.33%;
    }
  }
}

.instawidget-prev,
.instawidget-next {
  display: block;
  width: 50px; height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  opacity: .7;
  transition: all .25s ease;

  &:hover {
    opacity: 1;
  }

  &:before {
    content: '';
    display: block;
    width: 8px; height: @width;
    position: absolute;
    left: 50%; top: 50%;
    border-top: 2px solid $white;
  }

  &:not(.is-disabled) {
    cursor: pointer;
  }

  &.is-disabled {
    display: none;
  }
}

.instawidget-prev {
  // right: 100%;
  left: 15px;
  background: $black;

  &:before {
    border-left: 2px solid $white;
    transform: translate(-40%, -50%) rotate(-45deg);
  }
}

.instawidget-next {
  // left: 100%;
  right: 15px;
  background: $black;

  &:before {
    border-right: 2px solid $white;
    transform: translate(-60%, -50%) rotate(45deg);
  }
}
