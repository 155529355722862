
/*   -=========== Filter ===========-   */

$filters-gray = #e8e8e8;

.filter-aside {
  padding: 75px 50px 70px 80px;
  background: $filters-gray;
  max-width: 358px;
}

.left-filter--caption {
  @extend .h3;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.left-filter--toggler {
  width: 40px; height: 40px;
  position: absolute;
  right: -10px; top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    background: $black;
  }

  &:before {
    width: 14px; height: 2px;
  }

  &:after {
    width: 2px; height: 14px;
    opacity: 0;
    transition: opacity .25s ease;

    .is-collapsed & {
      opacity: 1;
    }
  }
}

.selected-filters {
  margin: 15px 0 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 568px) {
    margin-top: 0;
  }

  .item--label {
    color: $black;
    font-weight: 400;
    font-size: 14px;

    @media $small-mobile {
      font-size: 12px;
    }
  }

  .item {
    position: relative;
    padding: 10px 10px 10px 30px;
    margin-right: 15px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    border: 1px solid #E8E8E8;

    @media $small-mobile {
      padding: 10px 10px 10px 25px;
    }
  }
}

.selected-filters__remove {
  display: block;
  width: 10px; height: @width;
  position: relative;
  padding: 5px 10px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 12px; height: 2px;
    background-color: #c4c4c4;
    transition: all 150ms ease;

    @media $small-mobile {
      width: 15px;
    }
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: $black;
    }
  }
}

.selected-filters__clear {
  font-weight: 400;
  font-size: 14px;
  padding: 10px 35px;
  text-decoration: none;
  border: 1px solid $black;
  transition: all 150ms ease;
  margin-top: 15px;

  @media $small-mobile {
    font-size: 12px;
  }

  &:hover {
    background-color: $black;
    color: $white;
  }
}


.left-filter--group {
  border-top: 1px solid $white;
  padding-top: 10px;
}

.left-filter--body {
  overflow: hidden;
  max-height: 2000px;
  padding-bottom: 30px;
  transition: all .25s ease;

  .item {
    position: relative;

    a {
      text-decoration: none;
      font-weight: bold;
    }
  }

  .item + .item {
    margin-top: 10px;
  }

  .is-collapsed & {
    max-height: 0;
    opacity: 0;
    padding-bottom: 0;
  }
}

.checkbox-filter {
  color: $gray;
  font-weight: 300;
  cursor: pointer;
  transition: color .25s ease;

  &:after {
    content: '';
    display: block;
    width: 20px; height: 20px;
    background: url(/i/check.png) no-repeat 50%;
    position: absolute;
    right: 0; top: 0;
    opacity: 0;
    transition: opacity .25s ease;
  }

  &.checked {
    color: $black;

    &:after {
      opacity: 1;
    }
  }

  input {
    display: none;
  }
}

.colors-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  margin-left: -3px;

  .color-box {
    display: block;
    margin-right: 10px;
    margin-bottom: 1px;
    width: 28px; height: @width;
    position: relative;
    border-radius: 50%;

    &:before, &:after {
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 30px; height: @width;
      background-color: $filters-gray;
      transition: all .25s ease;
    }

    &:after {
      width: 20px; height: @width;
      background: inherit;
    }

    &.checked:before {
      width: 26px; height: @width;
    }
  }
}
