
/*   -=========== Mobile menu ===========-   */

.mobile-menu {
  position: fixed;
  left: 0; top: $mobile-header-height;
  z-index: 100;

  @supports ((position: sticky) or (position: -webkit-sticky)) {
    position: -webkit-sticky;
    position: sticky;
  }

  width: 100%; height: 'calc(100vh - %s)' % $mobile-header-height;
  max-height: 0;
  overflow-y: scroll;
  background: $white;
  border-top: 1px solid #000;
  opacity: 0;
  transition: all .25s ease;
  transform: translate3d(0, 0, 0);

  .mobile-menu-is-active & {
    max-height: 2000px;
    opacity: 1;
  }
}

.mobile-menu--search {
  padding: 5px 10px;
  position: relative;

  .inp {
    line-height: 31px;
    padding: 0 15px 0 35px;
    border: none;
    font-size: 1.3rem;
    background: #d5d8dc url(/i/search-icon.png) no-repeat 15px 50% / auto 13px;
  }

  .btn {
    position: absolute;
    left: 10px; top: 5px;
    width: 35px; height: calc(100% - 10px);
    background: transparent;
    font-size: 0;
  }
}

.mobile-menu--contact {
  padding: 25px;
  font-size: 1.4rem;

  &, a {
    color: $gray;
  }
}

.mobile-menu--contact-group {
  & + & {
    margin-top: 10px;
  }
}
