
/*   -=========== Popups ===========-   */

// Base

.popup {
  display: none;
  box-sizing: border-box;
  position: absolute;
  background: $body-bg;
  z-index: 333;
  padding: 30px 40px 35px;

  @media $small-mobile {
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
  }
}

.popup--close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0; right: 0;
  cursor: pointer;

  &:before, &:after {
    content: '';
    display: block;
    width: 2px; height: 20px;
    position: absolute;
    left: 50%; top: 50%;
    background: $black;
    margin-top: -9px;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
}

.popup--caption {
  @extend .h2;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.callback-popup,
.feedback-popup {
  max-width: 320px;
}

.order-size-popup {
  // width: 620px;
  max-width: 100%;
}


// Tooltip

.css-tooltip {
  display: inline-block;
  line-height: 1;
  border-bottom: 1px dashed rgba($black, .5);
  position: relative;

  &:before {
    content: attr(data-tooltip);
    display: block;
    width: 250px;
    max-height: 0;
    opacity: 0;
    background: rgba($black, .7);
    color: $white;
    transition: all .25s ease;
    padding: 0 10px;
    overflow: hidden;
    position: absolute;
    left: 50%; bottom: 100%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    line-height: 1.2;
  }

  &:hover:before {
    max-height: 800px;
    opacity: 1;
    padding: 10px 15px;
  }
}

// #47697: фикс смещения курсора поля ввода на iOS 11.0 - 11.2.6 (текущая) 19.03.2018
// возможно потом Apple нативно поправит баг
.is-ios.uniloader-overlay-html body{
  position: fixed;
}


// basket popup

.basket-popup {
  padding: 50px 45px 42px;
  width: 534px;
  max-width: 100%;
  box-sizing: border-box;
  font-size: 1.1rem;
  letter-spacing: .015em;

  @media $small-mobile {
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .popup--close {
    @extend .btn;
    width: 36px; height: 36px;
    border-radius: 50%;
    border: 3px solid $light-gray;
    background: none;
    transform: rotate(-45deg);
    box-sizing: border-box;
    right: 15px; top: 15px;

    &:before, &:after {
      background: $light-gray;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%) !important;
      margin: 0;
    }

    &:before {
      width: 3px !important; height: 16px !important;
    }

    &:after {
      width: 16px !important; height: 3px !important;
    }
  }
}

.basket-popup--product {
  display: flex;
  padding-bottom: 35px;
  border-bottom: 1px solid #cdcccc;
  margin-bottom: 1.5em;

  @media $small-mobile {
    flex-direction: column;
  }

  .product--image {
    flex: 1;
    margin-right: 19px;

    @media $small-mobile {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  .product--content {
    flex: 1.2;
  }

  .product--name {
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: .75em;
    margin-bottom: .75em;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 29px; height: 2px;
      background: $black;
      position: absolute;
      left: 0; bottom: 0;
    }
  }

  .product--params {
    .item + .item {
      margin-top: .25em;
    }
  }

  .product--size {
    margin-top: 35px;
    display: flex;

    .item {
      flex: 1;
    }

    .item + .item {
      margin-left: 10px;
    }

    .item--label {
      color: #777676;
      margin-bottom: .5em;
    }

    .item--value {
      font-weight: bold;
    }
  }
}

.basket-popup--info {
  margin-bottom: 3em;

  .item + .item {
    margin-top: 1em;
  }
}

.basket-popup--actions {
  display: flex;

  @media $small-mobile {
    flex-direction: column;
  }

  .button {
    @extend .btn;
    text-transform: uppercase;
    flex: 1;
    line-height: 52px;
  }

  .button + .button {
    margin-left: 19px;

    @media $small-mobile {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  .button_to-basket {
    @extend .orange-btn;
  }

  .button_close {
    @extend .black-bordered-btn;
  }
}
