
/*   -=========== Structure ===========-   */

// Base

body {
  display: table;
  width: 100%;

  &.mobile-menu-is-active {
    overflow: hidden;
    height: 100vh;
    display: block;
  }
}

#structure {
  display: table-row;
  height: 100%;
  margin: auto;
  position: relative;

  &::before {
    content: '';
    display: block;
    background: rgba($black, 0.4);
    position: fixed;
    left: 0; top: 0;
    width: 100vw; height: 100vh;
    box-sizing: border-box;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    transition: all 250ms ease;
  }

  &.overlay {
    &::before {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.footer {
  position: relative;
  background: $black;
  letter-spacing: 1px;

  &, a {
    color: $white;
  }
}


// Header

.header {
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  top: 0; left: 0;
  z-index: 1000;
  background: $white;
  border-bottom: 1px solid $pink-gray;
}

.header--inner {
  position: relative;
  transition: all .5s ease;

  @media $not-small-handheld {
    height: $header-height;
    border-bottom: 1px solid $pink-gray;

    .mini-header & {
      height: $minimized-header-height;
    }
  }

  @media $small-handheld {
    height: $mobile-header-height;
  }

  .wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header--logo {
  display: block;
  width: 100px; height: @width;
  background: url(/i/funhouse-black-logo.svg) no-repeat 50% / contain;
  font-size: 0;
  position: absolute;
  left: 50%; top: 50%;
  transform: translate(-50%, -50%);
  transition: all .5s ease;
  transition-property: width, height, margin-top;

  @media $not-small-handheld {
    margin-top: -5px;

    .mini-header & {
      width: 50px; height: 50px;
      margin-top: 0;
    }
  }

  @media $tablet {
    @media $not-small-handheld {
      margin-left: 5%;
    }
  }

  @media $small-handheld {
    width: 42px; height: 42px;
  }

  @media $small-mobile {
    left: 50%;
  }

  @media only screen and (max-width: 320px) {
    left: 40%;
  }
}

.header--mobile-menu-trigger {
  display: inline-block;
  vertical-align: middle;
  width: 40px; height: 40px;
  flex-shrink: 0;
  position: relative;
  margin-left: -11px;
  cursor: pointer;

  .bars {
    &, &:before, &:after {
      display: block;
      height: 2px;
      background: $black;
      position: absolute;
      transition: all .25s ease;
    }

    & {
      width: 18px;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before, &:after {
      content: '';
      left: 0;
    }

    &:before {
      width: 6px;
      bottom: 5px;
    }

    &:after {
      width: 12px;
      top: 5px;
    }

    .mobile-menu-is-active & {
      & {
        width: 0;
      }

      &:before, &:after {
        width: 18px;
        margin-left: -10px;
      }

      &:before {
        bottom: 0;
        transform: rotate(-45deg);
      }

      &:after {
        top: 0;
        transform: rotate(45deg);
      }
    }
  }

  @media $not-small-handheld {
    display: none;
  }
}

.header--left-side,
.header--right-side {
  display: flex;
}

.header--left-side {
  /* margin-top: -20px; */
  /* transition: margin-top .5s ease; */

  /* .mini-header & {
    margin-top: 0;
  } */
  margin-bottom: 11px;
  align-items: flex-end;

  &, a {
    color: $gray;
  }

  > * + * {
    @media $desktop {
      margin-left: 40px;
    }

    @media $tablet {
      margin-left: 20px;
    }
  }
}

.header--right-side {
  > * + * {
    @media $not-mobile {
      margin-left: 15px;

      @media $handheld {
        margin-left: 20px;
      }
    }

    @media $mobile {
      margin-left: 5px;
    }
  }

  @media $small-handheld {
    margin-right: -11px;
  }

  @media $landscape-tablet {
    flex-wrap: wrap;
  }
}

.header--phones {
  letter-spacing: 1px;

  @media $small-handheld {
    display: none;
  }
}

.header--worktime {
  font-size: 1.1rem;
  text-transform: uppercase;
  padding-bottom: 2px;

  @media $tablet {
    font-size: 1rem;
    line-height: 1.4;
  }

  @media $small-handheld {
    display: none;
  }
}

.header--worktime-value {
  font-weight: bold;
}

.header--contact {
  color: $gray;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

@media $handheld {
  .header--address {
    display: none;
  }
}

.header--icon {
  display: inline-block;
  vertical-align: middle;
  width: 40px; height: 40px;
  font-size: 0;
  transition: opacity .25s ease;

  &:hover {
    opacity: .5;
  }
}

$header-icons = search, contact, feedback;
for $icon in $header-icons {
  .header--icon_{$icon} {
    background: url('/i/' + $icon + '-icon.png') no-repeat 50%;
  }
}

$svg-icons = callback, basket;
for $icon in $svg-icons {
  .header--icon_{$icon} {
    background: url('/i/' + $icon + '-icon.svg') no-repeat 50%;
    background-size: size('i/' + $icon + '-icon.svg');
  }
}

.header--icon_search{
  background-position-y: calc(50% + 1px);
}
.header--icon_contact{
  background-position-y: 50%;
}
.header--icon_callback{
  background-position-y: calc(50% + 1px);
}
.header--icon_feedback{
  background-position-y: calc(50% + 2px);
}
.header--icon_basket{
  background-position-y: 50%;
}

@media $handheld {
  .header--icon_search {
    display: none
  }
}

@media $small-handheld {
  .header--icon_contact,
  .header--icon_feedback {
    display: none;
  }
}

@media $landscape-tablet {
  .header--icon_search {
    position: absolute;
    right: 0; top: 50%;
    transform: translateY(-50%);
  }
}

.header--search-container {
  position: relative;

  @media $landscape-tablet {
    width: 100%; height: 50px;
    order: 10;
  }

  @media $small-handheld {
    display: none;
  }

  .header--search,
  .header--search .search-submit {
    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }
}

.header--search-panel {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: $white;
  z-index: 1;
  display: none;

  &-close {
    position: absolute;
    right: -70px;
    top: 10px;
    font-size: 0;
    width: 23px;
    height: 23px;
    background: url('/i/close.png') no-repeat 50%/contain;
  }

  .header--logo {
    position: relative;
    left: 15%;
    top: 15%;
    transform: none;
    margin-top: 0;
  }
}

.header--search {
  // overflow: hidden;
  width: 350px;
  // max-width: 0;
  opacity: 1;
  position: absolute;
  left: 50%; top: 50%;
  transform: translate(-50%, -50%);
  transition: all .25s ease;

  @media $notebook {
    width: 200px;
  }

  @media $landscape-tablet {
    right: 40px;
  }

  @media $small-handheld {
    display: none;
  }

  .search-submit {
    display: block;
    font-size: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -30px;
    top: 10px;

    @media $handheld {
      top: 16px;
      right: 0;
    }
  }

  .header--search-container.is-active & {
    max-width: 190px;
    opacity: 1;

    @media $small-handheld {
      display: none;
    }
  }

  .inp {
    border-bottom-color: $pink-gray;
  }
}

@media $small-handheld {
  .header--basket,
  .header--icon_callback {
    order: 2;
  }
}

.header--basket a {
  text-decoration: none;
  transition: opacity .25s ease;

  &:hover {
    .header--icon_basket {
      opacity: 1;
    }

    opacity: .5;
  }
}

.header--icon_basket {
  position: relative;
}

.header--basket-text {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  letter-spacing: 1px;
  margin-left: 20px;

  @media $mobile {
    display: none;
  }
}

.header--basket-counter {
  display: block;
  width: 22px; height: 22px;
  line-height: 22px;
  text-align: center;
  background: #d5d8dc;
  border-radius: 50%;
  font-size: 1.2rem;
  position: absolute;
  top: -5px; right: -5px;
}

.header--currency {
  /* margin-right: calc(14px - 5vw); */
  margin-left: 2vw;
  align-self: center;

  @media $small-handheld {
    position: absolute;
    left: 60px; top: 50%;
    transform: translateY(-50%);
  }

  .selectric {
    border-color: #000;
    background: #000;

    .label {
      width: 32px; height: 25px;
      line-height: @height;
      margin-right: 6px;
      margin-left: 2px;
      color: $white;
      font-size: 1.3rem;
      font-weight: $semi-bold;
    }

    .button {
      width: 0; height: 0;
      position: absolute;
      right: 1px; top: 50%;
      border: 4px solid transparent;
      border-top-color: #929292;
    }
  }

  .selectric-items {
    border: 1px solid #000;

    li {
      line-height: 18px;
      min-height: 0;
      color: #929292;
      font-size: 1.3rem;
      font-weight: $semi-bold;
      padding: 0 0 0 8px;

      &.highlighted,
      &.selected {
        background: #000;
        color: $white;
      }
    }
  }
}

.header--language {
  display: flex;
  align-items: center;
  margin-left: 1vw;

  @media $small-handheld {
    order: 1;
  }

  // @media $small-handheld {
  //   // position: absolute;
  //   // left: 50px; top: 50%;
  //   // transform: translateY(-50%);
  //   display: none;
  // }

  .item {
    width: 29px; height: @width;
    line-height: @height;
    text-align: center;
    font-size: 1.2rem;
    font-weight: $semi-bold;
    color: $gray;
    text-decoration: none;
  }

  .item.is-active {
    background: $black;
    color: $white;
  }
}

.mobile-menu--language {
  display: flex;

  .item {
    @extend .btn;
    flex: 1;
    line-height: 29px;
    border-bottom: 1px solid #d5d8dc;
    font-size: 1.3rem;
    font-weight: $semi-bold;
    color: #929292;
  }

  .item.is-active {
    background: #000;
    color: $white;
    border-bottom-color: #000;
  }
}


// Content

#content {
  @media $not-small-handheld {
    padding-top: $header-height + $menu-height;
  }

  @media $small-handheld {
    padding-top: $mobile-header-height;
  }
}


// Footer

.footer--inner {
  padding: 70px 0;

  @media $mobile {
    padding: 50px 0;
  }
}

.footer--layout {
  display: flex;
  justify-content: space-between;

  @media $mobile {
    flex-direction: column;
    align-items: center;
  }
}

.footer--column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  @media $portrait-tablet {
    + .footer--column {
      margin-left: 20px;
    }
  }

  @media $mobile {
    align-items: center;

    + .footer--column {
      margin-top: 40px;
    }

    &:last-child {
      margin-top: 60px;
    }
  }
}

.footer--column-caption {
  @extend .caption;
  margin-bottom: 25px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 32px;

  @media only screen and (max-width: 1280px){
    font-size: 28px;
  }
  @media only screen and (max-width: 1024px){
    font-size: 24px;
  }
  @media only screen and (min-width: 701px) {
    padding-right: 25px;
  }

  &:after {
    background: $white;
  }

  @media $mobile {
    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.footer--logo {
  width: 130px; height: @width;
  background: url(/i/funhouse-white-logo.svg) no-repeat 50% / contain;

  @media $mobile {
    width: 76px; height: @width;
  }
}

@media $mobile {
  .footer--copyright {
    display: none;
  }
}

.footer--address {
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
  line-height: 1.5;
  text-decoration: none;

  &:after {
    content: '';
    display: block;
    width: 31px; height: 2px;
    background: $white;
    position: absolute;
    left: 0; bottom: 0;
  }

  @media $mobile {
    text-align: center;
    font-size: 1.4rem;

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.footer--phone {
  margin-bottom: 25px;
  line-height: 1.5;

  @media $mobile {
    font-size: 1.4rem;
    text-align: center;
  }
}


.footer--socials {
  $socials-list = fb, inst, pin, vk, tg;

  display: flex;

  .item {
    display: block;
    width: 33px; height: 33px;
    transition: opacity .25s ease;

    &:hover {
      opacity: .5;
    }
  }

  .item + .item {
    margin-left: 28px;

    @media $handheld {
      margin-left: 15px;
    }
  }

  for $social in $socials-list {
    .item_{$social} {
      background: url('/i/sm-' + $social + '-white.png') no-repeat 50%;
    }
  }

  @media $mobile {
    margin-top: 10px;
  }
}

.footer--dev-copyright {
  padding: 20px 0 25px;
  border-top: 1px solid $white;
  text-align: right;

  a {
    color: #fff;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;

    &:first-child::before{
      content: '';
      position: absolute;
      background: url(/i/shogo-white.svg) no-repeat 50%/contain;
      width: 54px;
      height: 36px;
      margin-left: -60px;
      margin-top: -10px;
    }
  }

  @media $mobile {
    a {
      text-align: center;
      font-size: 1.2rem;
      font-weight: normal;
    }

    a:before{
      width: 42px;
      height: 28px;
      margin-left: -46px;
      margin-top: -8px;
    }
  }
}


.footer--payments {
  display: flex;
  align-items: flex-start;

  .visa {
    width: 66px;
    margin-top: 0px;
    margin-right: 12px;
  }
  .mc {
    width: 60px;
    margin-right: 8px;
    margin-top: -13px;
  }
  .mir {
    width: 69px;
    margin-top: -2px;
  }
}



.go-top-btn {
  width: 45px; height: 45px;
  background: $white;
  position: fixed;
  right: 20px; bottom: 20px;
  z-index: 100;
  cursor: pointer;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all .25s ease;

  &.visible {
    opacity: .7;
    max-height: 45px;
  }

  &.visible:hover {
    opacity: 1;
  }

  &:after {
    content: '';
    display: block;
    width: 12px; height: 12px;
    border-top: 2px solid $black;
    border-right: 2px solid $black;
    position: absolute;
    left: 50%; top: 50%;
    transform: rotate(-45deg) translate(-30%, -50%);
  }
}

// Rotation warning

.rotate-device-warning {
  display: none;
  background: #000 url(/i/rotate-device.png) no-repeat 50% 40%;
  width: 100%; height: 100%;
  position: fixed;
  left: 0; top: 0;
  z-index: 10000;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;

  span {
    position: absolute;
    left: 50%; bottom: 20px;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  @media only screen and (max-device-width: 700px) and (min-device-width: 480px) and (orientation: landscape) {
    display: block;
  }
}

.alert {
  position: fixed;
  left: 0; bottom: 0;
  z-index: 9999;
  width: 100%;
  background-color: $white;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
  color: $black !important;
  opacity: 0;
  height: 0;
  transform: scaleY(0);
  transform-origin: 0 100%;
  transition: all 250ms ease;
  box-sizing: border-box;

  @media $small-notebook-and-less {
    font-size: 16px;
  }

  @media $small-handheld {
    font-size: 14px;
  }

  a {
    color: $black !important;
  }

  &.--shown {
    padding: 65px 15vw;
    height: 300px;
    transform: scaleY(1);
    opacity: 1;

    @media $small-handheld {
      padding: 30px 5vw;
      height: auto;
    }
  }
}

.alert-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  padding: 15px 65px;
  border: 3px solid $black;
  font-size: 18px;
  color: $black;
  text-decoration: none;
  transition: all 250ms ease;
  box-sizing: border-box;

  @media $handheld {
    font-size: 14px;
  }

  &:hover {
    background-color: $black;
    color: $white !important;
  }
}