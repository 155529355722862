
/*   -=========== Override vendor styles ===========-   */

.ui-autocomplete {
  z-index: 1010;
  width: 240px !important;
  max-height: 431px;
  overflow: auto;
  background: $white;
  list-style: none;
  word-wrap: break-word;
}
.ui-menu {
  .ui-menu-item {
    padding: 0;
    > a {
      display: block;
      color: $black;
    }
    &:hover {
      z-index: 10;
    }
    + .ui-menu-item {
      margin-top: -1px;
    }
  }
}
.autocomplete-item {
  padding: 5px;
  background: white;
  border: 1px solid $black;
  &:hover {
    border-color: $black;
    z-index: 10;
  }
  span {
    display: inline-block;
    max-width: 100%;
  }
}
.autocomplete-pic {
  float: left;
  width: 75px;
  height: 75px;
  text-align: center;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.autocomplete-content {
  display: block;
  font-size: 12px;
  line-height: 120%;
  .autocomplete-pic + & {
    margin-left: 85px;
  }
}
.autocomplete-price {
  padding-top: 10px;
}


// selectric

.selectric {
  border: 2px solid $gray;
  background: $white;
  transition: all .25s ease;

  .label {
    color: $gray;
    font-size: 1.4rem;
    font-weight: normal;
    height: 45px;
    line-height: 45px;
  }

  .button {
    width: 50px; height: 45px;
    font-size: 0;

    &:before {
      content: '';
      display: block;
      width: 5px; height: 5px;
      border-bottom: 2px solid $black;
      border-left: 2px solid $black;
      position: absolute;
      left: 50%; top: 50%;
      transform: rotate(-45deg) translateY(-50%);
      margin-top: -3px;
    }

    &:after {
      display: none;
    }
  }
}


// alertify

.alertify {
  border: 2px solid $black;
  width: 250px;
  margin-left: 0;
  top: 50%; left: 50% !important;
  transform: translate(-50%, -50%);
  background: $white;
}

.alertify-message {
  margin-bottom: 15px;
}

.alertify-button {
  display: inline-block;
  margin-left: 0 !important;
  line-height: 30px !important;
  width: 40px;
}

.alertify-button-ok {
  background: $black;
  color: $white;
  box-shadow: 0 0 0 2px $black;

  &:hover {
    background: $white;
    color: $black;
  }
}

.alertify-button-cancel {
  background: $white;
  box-shadow: 0 0 0 2px $black;
  margin-left: 10px !important;

  &:hover {
    background: $black;
    color: $white;
  }
}
