
/*   -=========== Menus ===========-   */

// Base

.menu {
  ul, ol, li {
    margin: 0;
    list-style: none;
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  .active > a {
    cursor: default;
  }

  li, a {
    user-select: none;
  }

  a:not([href]) {
    cursor: default;
  }
}

.inline-menu {
  li {
    display: inline-block;
  }
}

.horizontal-menu {
  ul {
    clearfix();
  }

  li {
    float: left;
  }
}

.vertical-menu {
  li {
    display: block;
  }
}

.justify-menu {
  > ul, > ol {
    line-height: 0;
    text-align: justify;

    &:after {
      width: 100%;
      height: 0;
      content: "";
      font-size: 0;
      line-height: 0;
      display: inline-block;
      visibility: hidden;
      overflow: hidden;
    }

    > li {
      display: inline-block;
    }
  }
}

.table-menu {
  ul {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  li {
    display: table-cell;
  }
}


// Header menu

.dropdown-colors-prototype {
  display: flex;
  flex-wrap: wrap;
  max-width: 310px;

  li:first-child {
    flex-basis: 100%;

    ~ li {
      margin-right: 10px;
    }
  }

  a {
    display: block;
    width: 28px; height: @width;
    position: relative;
    border-radius: 50%;

    &:before, &:after {
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 30px; height: @width;
      background-color: $white;
      transition: all .25s ease;
    }

    &:after {
      width: 20px; height: @width;
      background: inherit;
    }

    &:hover:before {
      width: 26px; height: @width;
    }
  }
}


.btn-see-all{
  @extend .btn, .black-bordered-btn;
  width: 180px;
  font-size: 1.6rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  &:hover{
    color: #ffffff !important;
  }
}


.header--menu {
  @extend .menu;
  margin-top: -1px;
  height: $menu-height;

  @media $small-handheld {
    display: none;
  }

  > ul {
    position: relative;
    height: $menu-height;
    display: flex;
    justify-content: space-between;
  }

  > ul > li > a {
    display: block;
    font-weight: $semi-bold;
    font-size: 1.6rem;
    line-height: $menu-height;
    text-transform: lowercase;
    font-variant: small-caps;
    letter-spacing: 1px;
    color: $gray;
    position: relative;
    transition: color .25s ease;

    &:before {
      content: '';
      display: block;
      width: 100%; height: 2px;
      max-width: 0;
      background: $black;
      position: absolute;
      top: 0; left: 50%;
      transform: translateX(-50%);
      transition: max-width .25s ease;
    }
  }

  .is-active a,
  a:hover {
    color: $black;

    &:before {
      max-width: 200px;
    }
  }

  .highlighted-item > a {
    font-weight: $extra-bold;
  }

  .has-dropdown > a {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 10px; height: 10px;
      background: $white;
      border-top: 1px solid #868483;
      border-right: 1px solid #868483;
      transform: rotate(-45deg);
      position: absolute;
      left: 50%; top: 100%;
      margin-left: -5px;
      opacity: 0;
      transition: all .25s ease;
    }
  }

  .has-dropdown.is-hovered {
    > a:after {
      opacity: 1;
      margin-top: -5px;
    }

    .dropdown {
      top: 100%;
      padding-top: 55px;
      max-height: 'calc(100vh - %s)' % ($header-height + $menu-height + 5px);
      overflow-y: auto;
      box-sizing: border-box;

      &:after {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100px;
      }
    }
  }

  .dropdown {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -80px;
    margin-top: 2px;
    background: $white;
    padding: 0 80px;
    box-shadow: 12px 12px 8px rgba($black, .27);
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    //transition: max-height .25s ease; #refs 50489

    &:before {
      content: '';
      display: block;
      width: 100%; height: 2px;
      position: absolute;
      left: 0; bottom: 100%;
    }

    @media $handheld {
      width: 100%;
    }
  }

  .hidden--column{
    display: none !important;
  }

  .dropdown--column {
    display: block;

    + .dropdown--column {
      margin-left: 75px;
    }
  }

  .dropdown--column li + li {
    margin-top: 15px;
  }


  .dropdown.dropdown-widemenu {
    margin-left: -40px;
    padding: 0 40px;
    flex-wrap: nowrap;

    @media only screen and (max-width: 1199px){
      flex-wrap: wrap;
    }

    .dropdown--column ul:last-child li:last-child {
      padding-bottom: 45px;
    }

    .dropdown--column + .dropdown--column {
      /* display: flex; */
      margin-left: 25px;
      margin-bottom: 20px;
    }
    .dropdown--column:nth-of-type(1) {
      //background-color: paleturquoise;
    }
    .dropdown--column:nth-of-type(2) {
      //background-color: palegoldenrod;
      /* display: flex;
      justify-content: flex-start; */
    }
    .dropdown--column:nth-of-type(2) ul:first-of-type li {
      padding-bottom: 10px;
    }
    .dropdown--column:nth-of-type(2) ul:nth-of-type(2) {
      //background-color: plum;
    }
    .dropdown--column:nth-of-type(3) {
      //background-color: palevioletred;
      margin-right: 25px;
    }
    .dropdown--column:nth-of-type(4) {
      //background-color: palegreen;
      /* margin-left: 0px; */
      margin-right: 45px;
      @media only screen and (max-width: 1199px){
        margin-right: 25px;
      }
    }
    .dropdown--column:first-of-type {
      //background-color: plum;
      display: flex;
      box-sizing: border-box;
      flex-direction: row;
      margin-left: 10px;

      .dropdown--actions {
        //background red;
        display: flex;
        flex-wrap: wrap;
        max-width: 310px;
        max-width: 180px;
        min-height: 220px;
        order: 2;
        flex-direction: column;

        @media (max-width: 1280px) {
          flex-wrap: nowrap;
        }

        li:first-child{
          max-height: 34px;
        }

        .dropdown--caption {
          display: block;
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.2;
          padding-bottom: 15px;
          text-transform: uppercase;
        }
      }
      .dropdown--colors{
        //background lime;
        order: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        max-height: 160px;
        box-sizing: border-box;
        width: 240px;
        min-width: 240px;
        margin-bottom: 35px;

        @media (max-width: 1280px) {
          max-height: none;
          align-content: flex-start;
        }
      }
    }
    &:after {
      padding-bottom: 30px !important;
    }

    .dropdown--caption {
      display: block;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.2;
      padding-bottom: 15px;
      text-transform: uppercase;
    }

    .dropdown li a {
      line-height: 1;
      font-size: 1.6rem;
      transition: color .25s ease;

      &:hover {
        color: $gray;
      }
    }

    @media only screen and (max-width: 1350px) {

      .dropdown--column:last-of-type {
        display: flex;
        flex-direction column;
        justify-content: flex-start;
        margin-left: 0;

        .dropdown--colors{
          padding-bottom: 20px;
          margin-bottom: 22px;
        }
        .dropdown--actions{

        }
      }
    }

    @media only screen and (max-width: 915px){

      .dropdown--column:nth-of-type(1),
      .dropdown--column:nth-of-type(2){
        margin-right: 40px;
      }
      .dropdown--column:nth-of-type(3) {
        margin-right: 0;
      }
      .dropdown--column:nth-of-type(4) {
        margin-right: 20px;
      }
      .dropdown--column:last-of-type {
        display: flex;
        flex-direction row;
        margin-left: 0;

        .dropdown--actions{
        //background-color orangered;
          display: flex;
          flex-direction column;

          li:first-child{
            max-height: 36px;
          }
        }
        .dropdown--colors{
          width: 230px;
          min-height: 200px;
        }
      }
    }
  }

  .dropdown--caption {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.2;
    padding-bottom: 15px;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .dropdown li a {
    line-height: 1;
    font-size: 1.6rem;
    transition: color .25s ease;
    display: block;
    max-width: 200px;
    white-space: nowrap;

    &:hover {
      color: $gray;
    }
  }

  .dropdown--colors {
    @extend .dropdown-colors-prototype;

    li a:after{
      box-shadow: 0 0 1px 1px rgba(0,0,0,0.25);
    }
  }

  .dropdown--actions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 310px;

    li:first-child {
      flex-basis: 100%;

      ~ li {
        margin-right: 17px;
      }
    }

    .dropdown--caption {
      font-size: 1rem;
    }
  }

  .btn-see-all{
    height: 40px;
    line-height: 36px !important;
    margin-bottom: 28px;
  }
}


// Mobile menu

.mobile-menu .menu > ul {
  $mobile-menu-bg = #e8e8e8;

  a {
    display: block;
  }

  > li {
    border-bottom: 1px solid #d5d8dc;
  }

  > li > a {
    padding: 12px 25px;
    font-size: 1.2rem;
    font-weight: $semi-bold;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $gray;
  }

  .dropdown {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all .25s ease;

    ul:not(.dropdown--colors) a {
      padding: 8px 25px;
      color: $gray;
      font-size: 1.2rem;
    }
  }

  .dropdown--column {
    padding-bottom: 10px;

    &:before {
      content: '';
      display: block;
      height: 1px;
      margin: 0 25px 8px;
      background: $white;
    }
  }

  .dropdown--caption {
    display: block;
    padding: 10px 25px;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .dropdown--colors {
    @extend .dropdown-colors-prototype;
    padding: 0 20px;
    margin-right: -8px;

    li:first-child ~ li {
      margin-right: 8px;
    }

    a:before {
      background-color: $mobile-menu-bg;
    }

    .dropdown--caption {
      padding-left: 5px;
    }
  }

  .has-dropdown.is-hovered {
    background: $mobile-menu-bg;
  }

  .is-hovered .dropdown {
    max-height: 3000px;
    opacity: 1;
    padding-bottom: 25px;
  }

  .btn-see-all{
    color: #000000 !important;
    font-size: 1.4rem !important
    line-height: 20px !important;
    height: 24px;
    margin-top: 4px;
    margin-bottom: 8px;
    margin-left: 26px;

    &:hover{
      color: #ffffff !important;
    }
  }
}


// Footer menu

.footer--menu {
  @extend .menu;
  flex: 1;

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  li + li {
    margin-top: 10px;
  }

  a {
    transition: opacity .25s ease;
  }

  a:hover {
    opacity: .5;
  }

  @media $mobile {
    li {
      text-align: center;
    }

    li + li {
      margin-top: 15px;
    }

    a {
      font-size: 1.4rem;
    }
  }
}


// Breadcrumbs

.breadcrumbs {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: $black;

  @media $mobile {
    display: none;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }

  li + li {
    margin-left: 25px;

    &:before, &:after {
      content: '';
      display: block;
      width: 1px; height: 7px;
      background: $black;
      position: absolute;
      right: 100%;
      margin-right: 10px;
    }

    &:before {
      bottom: 50%;
      margin-bottom: 1px;
      transform: rotate(-45deg);
      transform-origin: right bottom;
    }

    &:after {
      top: 50%;
      transform: rotate(45deg);
      transform-origin: right top;
    }
  }

  a, span {
    display: block;
    line-height: 50px;
  }

  a {
    text-decoration: none;
    color: $gray;

    &:hover {
      color: $black;
    }
  }
}
@media $mobile {
  .breadcrumbs-m10-sm{
    display: block;
    height: 20px;
  }
}

// fix для бага курсора в iOS 11: refs #47697
body.mobile-menu-is-active{
  position: fixed;
}


.one-column {
  columns: 1;
  width: 150px;
}
.two-columns {
  columns: 2;
  width: 300px;
}
.three-columns {
  columns: 3;
  width: 450px;
}



.pager {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 2.5em;
  li {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background-color: white;
    list-style: none;
    border: 1px solid #aaaaaa;
    padding: 0;
    border-radius: 50%;
    margin-left: 8px;
    margin-bottom: 4px;
  }
  li:first-of-type {
    margin-left 0;
  }
  li a {
    text-decoration: none;
    display: block;
    width: 32px;
    height: 32px;
    line-height 32px;
    position: absolute;
    font-weight: 600;
    color: #888;
  }
  li.hidden + li {
    margin-left: 0;
  }
  li.previous {
    font-size: 0;
    transition: .2s;
    margin-right: 12px;

    @media $small-mobile {
      margin-right: 5px;
    }

    &:hover {
      background-color: #000000;
      border: 1px solid #000000;

      &::after {
        border-color: $white;
      }
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 50%; left: 55%;
      transform: translate(-50%, -50%) rotate(45deg);
      position: relative;
      width: 7px; height: @width;
      border-left: 2px solid $gray;
      border-bottom: 2px solid $gray;
      pointer-events: none;
    }
  }
  li.next {
    font-size: 0;
    transition: .2s;
    margin-left: 20px;

    @media $small-mobile {
      margin-left: 13px;
    }

    &:hover {
      background-color: #000000;
      border: 1px solid #000000;

      &::after {
        border-color: $white;
      }
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 50%; left: 45%;
      transform: translate(-50%, -50%) rotate(225deg);
      position: relative;
      width: 7px; height: @width;
      border-left: 2px solid $gray;
      border-bottom: 2px solid $gray;
      pointer-events: none;
    }
  }
  li.active {
    border: 1px solid #000000;
    background-color: #000000;
    transition: .3s;
  }
  li.active:hover {
    background-color: #333333;
  }
  li.active a {
    color: #ffffff;
  }
  li.page--separator {
    border: none;
    width: auto;
    padding: 0;
    font-size: 1rem;
  }
}

#next-button-container {
  margin-top: 1em;
  text-align: center;

  a {
    display: inline-block;
    padding: 0.5em 1.5em;
    border: 1px solid $black;
    transition: all 0.25s ease;

    &:hover {
      background: $black;
      color: $white;
    }
  }
}
