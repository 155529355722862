
/*   -=========== Homepage ===========-   */

.rotator-wrapper {
  position: relative;
}

.rotator-wrapper_main {
  margin-bottom: 20px;

  @media $portrait-tablet {
    height: 674px;
  }

  @media $mobile {
    height: 374px;
  }

  @media $small-mobile {
    margin-bottom: 10px;
  }

  .main-rotator {
    height: 100%;
    overflow: hidden;
    line-height: 0;

    @media $mobile {
      img {
        max-width: none;
        height: 100%;
        position: absolute;
        left: 50%; top: 0;
        transform: translateX(-50%);
      }
    }

    .main-rotator__button {
      @extend .btn, .white-bordered-btn;
      line-height: 49px;
      padding: 0 70px;
      font-size: 1.8rem;
      position: absolute;
      left: 50%;
      bottom: 5%;
      transform: translateX(-50%);
      z-index: 10;
      white-space: nowrap;
      background-color: rgba(51, 51, 51, 0.5);

      @media $small-handheld {
        font-size: 1.4rem;
        line-height: 40px;
        padding: 0 35px;
      }
    }
  }

  .tos-uibg {
    display: none;
  }

  .tos-wrapper.tos-has-bullets .tos-pagination {
    width: auto; height: auto;
    left: auto; bottom: 8%; right: 5%;
    overflow: visible;
    opacity: 1;

    @media $small-mobile {
      display: none;
    }

    a {
      width: 12px; height: @width;
      margin: 0 7px;
      background: $black;
      opacity: .3;
      transition: opacity .25s ease;

      &.tos-selected {
        opacity: 1;
      }
    }

    &:before, &:after {
      display: none;
    }
  }

  /* &:after {
    content: '';
    display: block;
    width: 2px; height: 31px;
    background: $black;
    position: absolute;
    left: 50%; bottom: -16px;
    margin-left: -1px;
    z-index: 500;
  } */
}


.homepage-sliders-container {
  margin: 100px 0;

  @media $mobile {
    margin: 50px 0 60px;
  }

  @media $small-mobile {
    margin-top: 0;
  }
}

.homepage-slider-item {
  @media $small-mobile {
    position: relative;
  }

  .category {
    display: none;

    @media $small-mobile {
      display: flex;
      height: 300px;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin-bottom: 30px;
      margin-left: -15px;
      margin-right: -15px;
      background-image: url(/i/oboi.jpg?v=2?v=4);
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  .category--caption {
    @media $small-mobile {
      font-size: 3rem;
      font-weight: bold;
      text-transform: lowercase;
      font-variant: small-caps;
      letter-spacing: 1px;
      color: $white;
    }
  }

  &.wrapper {
    @media $small-mobile {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.homepage-slider-item__heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & > span {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 3.2rem;
    letter-spacing: 2px;
    font-variant: small-caps;

    @media $mobile {
      font-size: 1.8rem;
    }

    @media $small-mobile {
      font-size: 2.5rem;
    }
  }

  & > a.btn-see-all {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: flex-start;
    line-height: 45px;

    @media $small-mobile {
      position: absolute;
      top: 190px;
      background-color: transparent;
      box-shadow: inset 0 0 0 2px #FFF;
      left: 0;
      right: 0;
      margin: 0 auto;
      color: $white;
      background-color: rgba(51, 51, 51, 0.5);
    }
  }
}

.homepage-slider {
  min-width: 0;

  @media $small-mobile {
    margin-left: -5px;
    max-width: calc(100vw - 30px);
  }

  .vitrine-gallery .slick-track {
    @media $small-mobile {
      height: 200px;
    }
  }

  .product {
    width: 100%;
  }

  .tos-slide + .tos-slide {
    margin-left: 15px;

    @media $handheld {
      margin-left: 5px;
    }
  }

  .tos-slide {
    width: 24.25% !important;
    vertical-align: top;

    // @media (min-width: 701px and max-width: 1024px) {
    //   width: 33% !important;
    // }

    @media $handheld {
      width: 49.5% !important;
    }
  }

  .product--link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute !important;
    left: 0 !important;
    top: 0;
    z-index: 0;
  }

  .product--image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 0.25s ease;
  }

  .product--content {
    padding-top: 5px;
    padding-left: 15px;
  }

  .product--name {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 5px;

    @media $mobile {
      font-size: 1.2rem;
    }

    @media $small-mobile {
      font-size: 1rem;
    }
  }

  .product--price {
    font-size: 1.6rem;
    font-weight: normal;

    @media $mobile {
      font-size: 1.2rem;
    }
  }
}

.homepage-slider-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  margin-top: 30px;

  @media $mobile {
    margin-top: 10px;
  }

  div {
    font-size: 0;
    width: 40px;
    height: 40px;
    background-color: $black;
    position: relative;
    filter: brightness(.2);
    cursor: pointer;
  }

  div.tos-disabled {
    opacity: 0.4;
  }

  .js-prev {
    background: url(/i/arrow-left.svg) 50% / contain no-repeat;
  }

  .js-next {
    background: url(/i/arrow-right.svg) 50% / contain no-repeat;
  }
}

.homepage-costumers__photos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  column-gap: 21px;
  margin-bottom: 100px;
  margin-top: 100px;

  @media $mobile {
    margin-top: 0;
  }

  @media $small-mobile {
    column-gap: 10px;
  }

  a {
    display: block;
  }

  .homepage-slider-item__heading {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    width: 100%;

    @media $small-mobile {
      width: unset;
    }
  }

  .wrapper {
    @media $small-mobile {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .homepage-costumers__photos-left {
    grid-row: 2 / 3;
    grid-column: 1 / 2;

    @media $small-mobile {
      height: 550px;
    }

    a:first-child img {
      max-height: 350px;
      margin-bottom: 21px;

      @media $handheld {
        max-height: 300px;
      }

      @media $mobile {
        max-height: 36vw;
      }

      @media $small-mobile {
        max-height: 250px;
        height: 250px;
        margin-bottom: 10px;
      }
    }

    a:last-child img {
      max-height: 650px;

      @media $small-notebook-and-less {
        max-height: 500px;
      }

      @media $handheld {
        max-height: 450px;
      }

      @media $mobile {
        max-height: 43vw;
      }

      @media $small-mobile {
        max-height: 300px;
        height: 300px;
      }
    }
  }

  .homepage-costumers__photos-right {
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    @media $small-mobile {
      height: 550px;
    }

    a:first-child img {
      max-height: 650px;
      margin-bottom: 21px;

      @media $small-notebook-and-less {
        max-height: 500px
      }

      @media $handheld {
        max-height: 450px;
      }

      @media $mobile {
        max-height: 43vw;
      }

      @media $small-mobile {
        max-height: 300px;
        height: 300px;
        margin-bottom: 10px;
      }
    }

    a:last-child img {
      max-height: 350px;

      @media $handheld {
        max-height: 300px;
      }

      @media $mobile {
        max-height: 36vw;
      }

      @media $small-mobile {
        max-height: 250px;
        height: 250px;
      }
    }
  }

  img {
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
  }

  .btn-see-all {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    line-height: 50px;
    justify-self: center;
    margin-top: 50px;
  }
}


// About section

.about-section {
  @media $not-mobile {
    padding-top: 95px;
    padding-bottom: 80px;

    .wrapper {
      background: url(/i/home-pic-1.jpg) no-repeat 0 50%;
      min-height: 440px;

      @media $tablet {
        background-size: 45% auto;
      }
    }
  }

  @media $mobile {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media $not-mobile {
  .about-section--content {
    padding-left: 50%;
    position: relative;
  }
}

.about-section--title {
  font-weight: bold;
  position: absolute;
  left: 0; bottom: 40px;
  letter-spacing: 1px;

  @media $mobile {
    display: none;
  }
}

.about-section--caption {
  @extend .caption;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;

  &:after {
    display: none;
  }
}

.about-section--subcaption {
  @extend .h1;
  text-transform: none;
  font-variant: normal;
  font-weight: $light;
  line-height: 1.2;
  letter-spacing: 2px;
  margin-bottom: 30px;
}

.about-section--text {
  letter-spacing: 1px;

  @media $not-mobile {
    font-size: 1.6rem;
    line-height: 1.75;
    text-align: justify;
  }

  @media $mobile {
    font-size: 1.4rem;
    line-height: 1.35;
  }
}


// Categories

.categories-section + .categories-section {
  margin-top: 20px;

  @media $small-mobile {
    margin-top: 10px;
  }
}

.categories-section {
  display: flex;

  @media $mobile {
    flex-wrap: wrap;
  }

  .category {
    flex: 1;
    display: block;
    position: relative;
    background-position: 50%;
    background-size: cover;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &.category_half-height:before {
      padding-bottom: 50%;
    }

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: rgba($black, .5);
      position: absolute;
      left: 0; top: 0;
      opacity: .3;
      transition: opacity .25s ease;
    }

    /* @media $mobile {
      flex-basis: 50%;
    } */
  }

  .category_flex {
    line-height: 0;

    &:before {
      display: none;
    }

    @media $not-small-mobile {
      &:first-child {
        margin-left: 22px;
      }

      &:last-child {
        margin-right: 22px;
      }
    }

    @media $small-mobile {
      flex-basis: 100%;
    }

    &:after {
      height: 275px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;

      @media $small-handheld {
        height: 160px;
      }
    }

    .category--caption {
      bottom: 50%;
      margin-bottom: 40px;
    }

    .category--button {
      @extend .btn, .white-bordered-btn;
      line-height: 49px;
      padding: 0 30px;
      font-size: 1.8rem;
      position: absolute;
      left: 50%; top: 50%;
      transform: translateX(-50%);
      margin-top: 5px;
      z-index: 10;
      white-space: nowrap;

      @media $small-handheld {
        font-size: 1.4rem;
        line-height: 40px;
      }
    }

    &:hover .category--button {
      background: $white;
      color: $black;
    }
  }

  .category + .category,
  .category + .categories-section--half-block {
    @media $not-small-mobile {
      margin-left: 21px;
    }

    @media $small-mobile {
      margin-top: 10px;
    }
  }

  .categories-section--half-block.horizontal {
    .category + .category {
      margin-left: 0;
      margin-top: 20px;

      @media $small-mobile {
        margin-top: 10px;
      }
    }
  }

  .category--caption {
    width: 90%;
    font-size: 3.2rem;
    font-weight: bold;
    text-transform: lowercase;
    font-variant: small-caps;
    letter-spacing: 1px;
    color: $white;
    text-align: center;
    position: absolute;
    left: 50%; bottom: 15%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: color .25s ease;

    @media $landscape-tablet {
      font-size: 2rem;
    }

    @media $portrait-tablet {
      font-size: 1.6rem;
    }

    @media $mobile {
      font-size: 1.3rem;
    }
  }

  .category--more-icon {
    display: block;
    width: 55px; height: 55px;
    background: url(/i/more-icon.png) no-repeat 50%;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    transition: opacity .25s ease;
  }

  .category:hover {
    .category--caption {
      color: $white;
    }

    .category--more-icon {
      opacity: 1;
    }

    &:after {
      opacity: 1;
    }
  }

  .category_big {
    .category--caption {
      bottom: 7.5%;
    }

    @media $small-mobile {
      flex-basis: 100%;
    }
  }

  .category_wide {
    min-height: 240px;
    max-height: 380px;

    &:before {
      padding-bottom: 20vw;
    }
    .category--caption {
      bottom: 7.5%;
    }
  }
}

.categories-section--triple {
  @media $mobile {
    margin-bottom: 50px;
  }

  @media $small-mobile {
    .category + .category {
      margin-top: 0;
      margin-left: 5px;
    }

    .category {
      display: flex;
      justify-content: center;
      text-decoration: none;
    }

    .category--caption {
      position: unset;
      height: fit-content;
      margin-top: 60%;
      transform: unset;
    }
  }
}

.categories-section--catalog {
  @media $small-mobile {
    display: none;
  }
}

.categories-section--half-block {
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  .category {
    flex-basis: 50%;
  }

  &.horizontal {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  @media $mobile {
    flex-basis: 100%;

    &.horizontal {
      flex-direction: row;

      .category {
        flex-basis: 100%;
      }
    }
  }

  @media $small-mobile {
    flex-wrap: wrap !important;
  }
}

// Showroom

.showroom-section {
  padding-top: 85px;

  .wrapper {
    padding-bottom: 85px;
  }
}

.showroom-section--caption {
  @extend .caption;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.showroom-section--address {
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1em;

  @media $mobile {
    font-size: 1.6rem;
  }
}

.showroom-section--subcaption {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1em;
}

.showroom-section--text {
  font-size: 1.3rem;
  line-height: 1.5;

  .big-text {
    font-size: 1.5rem;
  }
}


// Features

$feature-padding-left = 85px;

.shop-features-section {
  padding-top: 80px;
  padding-bottom: 55px;

  @media $mobile {
    padding-top: 45px;
    padding-bottom: 35px;
  }
}

.shop-features-section--caption {
  @extend .caption;
  text-transform: uppercase;

  &:after {
    display: none;
  }

  @media $not-mobile {
    margin-left: $feature-padding-left;
  }
}

.shop-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .feature {
    @media $not-mobile {
      flex-basis: calc(50% - 60px);
      margin-top: 30px;
      margin-bottom: 30px;
    }

    @media $mobile {
      flex-basis: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .feature--number {
    font-weight: bold;
    line-height: .8;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &:after {
      content: '';
      display: block;
      height: 2px;
      background: $black;
      flex-basis: 'calc(100% - %s)' % $feature-padding-left;
    }

    @media $not-mobile {
      font-size: 6.4rem;
      margin-bottom: 30px;
    }

    @media $mobile {
      font-size: 3rem;
      margin-bottom: 20px;

      &:after {
        flex-basis: calc(100% - 44px);
      }
    }
  }

  .feature--caption {
    font-weight: bold;
    text-transform: uppercase;

    @media $not-mobile {
      padding-left: $feature-padding-left;
      font-size: 2.4rem;
      margin-bottom: 20px;
    }

    @media $mobile {
      font-size: 1.4rem;
      margin-bottom: 15px;
    }
  }

  .feature--text {
    letter-spacing: 1px;
    text-align: justify;
    color: $gray;

    @media $not-mobile {
      padding-left: $feature-padding-left;
      line-height: 1.75;
    }

    @media $mobile {
      font-size: 1.4rem;
      line-height: 1.35;
    }
  }
}


// Clients

.clients-section {
  padding-top: 110px;
  padding-bottom: 130px;

  @media $mobile {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.clients-section--caption {
  @extend .h1;
  text-align: center;
  text-transform: uppercase;

  @media $not-mobile {
    margin-bottom: 60px;
  }

  @media $mobile {
    margin-bottom: 20px;
  }
}

.clients-slider-wrapper {
  position: relative;

  @media only screen and (max-width: $wrapper-width + 100px) {
    padding: 0 60px;
  }

  @media $mobile {
    padding: 0;
    text-align: center;
  }
}

.clients-slider {
  .tos-slider {
    display: flex;
    //flex-direction: column;
    //flex-wrap: wrap;
    height: 175px;
  }

  .tos-slide {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 33.33% !important;
    //height: 33.33% !important;
    width: 25% !important;
    height: 100% !important;

    /* &:nth-child(even) {
      border-top: 1px solid #e4e4e4;
    }

    &:nth-child(2) ~ .tos-slide {
      border-left: 1px solid #e4e4e4;
    } */
  }

  .item {
    display: block;
    text-decoration: none;
    filter: grayscale(1);
    opacity: .5;
    transition: all .25s ease;

    &:hover {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  img {
    max-width: 90%;
  }

  @media $mobile {
    .item + .item {
      margin-top: 20px;
    }

    img {
      max-width: 132px;
      max-height: 40px;
    }
  }
}

.homepage-text {
  margin-top: 60px;
  font-size 16px;
  font-weight: normal;
  line-height: 1.5em;
}

.clients-slider--control {
  display: none;
  width: 55px; height: 65px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity .25s ease;

  &:before, &:after {
    content: '';
    display: block;
    width: 2px; height: 22px;
    background: $black;
    position: absolute;
  }

  &:before {
    bottom: 50%;
  }

  &:after {
    top: 50%;
  }

  &:focus {
    margin-top: -1px;
  }

  &:hover {
    opacity: .6;
  }
}

.clients-slider--prev {
  right: 100%;
  margin-right: 15px;

  &:before, &:after {
    left: 50%;
    margin-left: -10px;
  }

  &:before {
    transform: rotate(45deg);
    transform-origin: bottom left;
  }

  &:after {
    transform: rotate(-45deg);
    transform-origin: top left;
  }

  @media only screen and (max-width: $wrapper-width + 100px) {
    right: auto;
    left: 0;
  }
}

.clients-slider--next {
  left: 100%;
  margin-left: 15px;

  &:before, &:after {
    right: 50%;
    margin-right: -10px;
  }

  &:before {
    transform: rotate(-45deg);
    transform-origin: bottom right;
  }

  &:after {
    transform: rotate(45deg);
    transform-origin: top right;
  }

  @media only screen and (max-width: $wrapper-width + 100px) {
    left: auto;
    right: 0;
  }
}
