
/*   -=========== Buttons ===========-   */

.btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all .25s ease;
}

// use box-shadow instead of border to preserve button line-height
bordered-btn($border, $color) {
  box-shadow: inset 0 0 0 $border $color;
}

.black-bordered-btn {
  color: $black;
  bordered-btn(2px, $black);
  background: transparent;

  &:hover {
    color: $white;
    background: $black;
  }
}

.white-bordered-btn {
  color: $white;
  bordered-btn(2px, $white);
  background: transparent;

  &:hover {
    color: $black;
    background: $white;
  }
}

.black-btn {
  background: $black;
  color: $white;
  bordered-btn(1px, $black);

  &:hover {
    color: $black;
    background: $white;
  }
}

.orange-btn {
  background: #f39406;
  color: $white;

  &:hover {
    color: $white;
    background: $black;
  }
}


// Sizes

.wide-btn {
  width: 100%;
}

.h49btn {
  line-height: 49px;
}

.p20btn {
  padding-left: 20px;
  padding-right: 20px;
}
