
/*   -=========== Infopage ===========-   */

.infopage{

  // for headings
  .decorated{
    margin-bottom: 48px;
  }
  .decorated:before{
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    content: '';
    margin-top: 24px;
    border-bottom: 2px solid #000;
  }

  .text-container{
    font-weight: 400;
    letter-spacing: .66px;
    color: #3a3a3a;
    line-height: 1.5;
  }

  .h1.decorated:before {
    margin-top: 42px;
  }

  .contact--content {
    justify-content: flex-start;
    margin-top: 2em;

    .contact--group {
      flex: 1;
      max-width: 280px;
      order: initial !important;

      p + p {
        margin-top: 0;
      }
    }

    .contact--group + .contact--group {
      max-width: none;
      margin-left: 25px;

      @media $small-mobile {
        margin-left: 0;
      }
    }
  }
}

.info-contact-caption {
  @media $small-mobile {
    font-variant: initial;
    text-transform: uppercase;
  }
}


.materials-page {

  .materials-section {
    display: grid;
    grid-template-columns: 35% 65%;

    @media $mobile {
      grid-template-columns: 100%;
    }
  }

  .materials-section--heading {
    background-color: #1A1919;
    color: #ffffff;
    font-weight: bold;
    padding-top: .75em;
    padding-bottom: .75em;
    max-width: 100%;
    flex-basis: 100%;
  }

  .materials-section--video {
    video {
      width: 100%;
      height: 605px;
      object-fit: cover;
    }
  }

  .materials-section--text-container {
    height: auto;
    min-height: 500px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media only screen and (max-width: 1280px){
      height: inherit;
      padding-top: 2em;
      padding-bottom: 2em;
    }

    @media only screen and (max-width: 1024px){
      max-width: 100%;
      flex-basis: 100%;
      padding: 0;
    }
  }

  .materials-section--text-heading {
    font-size: 22px;
    letter-spacing: 5.25px;
  }

  .materials-section--text-price {
    font-size: 22px;
    letter-spacing: 4.25px;
  }

  .materials-section.section_1 .materials-section--text-container {
    padding: 2em 1em 3em 2.5em;
    font-weight: $medium;

    @media only screen and (max-width: 1024px){
      padding-top: 2em;
      padding-left: 1.5em;
    }

    @media $mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.guide-wrapper {
  position: relative;
}

.guide-download {
  display: block;
  width: 100%;
  line-height: 55px;
  text-decoration: none;
  text-align: center;
  background-color: $black;
  color: $white;
  position: absolute;
  bottom: 0;
  margin: 0;

  &:hover {
    color: $black;
    background-color: $white;
    outline: 1px solid $black;
  }
}
