
/*   -=========== Tabs ===========-   */

.tabs-nav {
  border-bottom: 1px solid $light-gray;
  font-size: 0;

  li {
    display: inline-block;
  }

  li + li {
    margin-left: 45px;
  }

  a {
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    padding: 10px 0;
    color: $light-gray;
    position: relative;
    transition: all .25s ease;
    text-decoration: none;

    &:after {
      content: '';
      display: block;
      width: 100%; height: 0;
      position: absolute;
      left: 0; bottom: -1px;
      background: $black;
      opacity: 0;
      transition: all .25s ease;
    }
  }

  .is-active a {
    color: $black;

    &:after {
      height: 2px;
      opacity: 1;
    }
  }
}

.tabs-panel {
  display: none;
}
