
/*   -=========== Search results ===========-   */

.search-page {
  padding-top: 20px;
  margin-bottom: 50px;

  @media $small-handheld {
    padding-top: 20px;
  }
}

.search-page--caption {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.ya-site-form__submit {
  @extend .btn, .h49btn, .black-btn;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
}

#ya-site-form0 .ya-site-form__search-input .ya-site-form__submit {
  width: 100px;
  color: $white !important;
  font-size: 1.6rem !important;

  &:hover {
    color: $black !important;
  }
}

#ya-site-form0 .ya-site-form__form .ya-site-form__input-text {
  font-size: 1.6rem !important;
  line-height: 47px;
  padding: 0 10px !important;
}

#ya-site-form0 .ya-site-form__search-input {
  padding: 0 !important;
}

@media $mobile {
  #ya-site-results {
    .b-body-items {
      padding-left: 25px;
    }

    .b-serp-url__item {
      word-break: break-all;
    }

    .g-gap-horizontal {
      margin-left: 0;
    }

    .b-pager__key {
      display: none;
    }

    .b-pager__arrow,
    .b-pager__title {
      font-size: .8em;
    }
  }

  #ya-site-form0 .ya-site-form__search-input .ya-site-form__submit {
    width: 60px;
  }
}

.search-results-form {
  display: flex;

  @media $not-small-handheld {
    max-width: calc(75% - 7px);
  }

  .btn {
    @extend .black-btn;
    font-size: 1.6rem;
    font-variant: small-caps;
    text-transform: lowercase;
    font-weight: bold;
    padding: 0 20px;
    margin-left: 10px;
  }
}

.vitrine.vitrine_search {
  margin-left: 0;
  margin-right: -15px;
}

.search-page .ias-trigger-prev {
  position: relative;
  margin-bottom: 20px;
}
