
/*   -=========== Vitrine ===========-   */

.vitrine {
  display: flex;
  flex-wrap: wrap;

  .product {
    box-sizing: border-box;
    /* border-bottom: 1px solid $light-gray; */
    //border: 1px solid $light-gray;
    position: relative;
    margin-bottom: 15px;
    margin-right: 15px;
    width: 30%;

    //&:before {
    //  content: '';
    //  display: block;
    //  padding-bottom: 100%;
    //}

    .product--image::before {
      content: none;
    }
  }

  &:not(.catalogue) .product {
    @media $not-small-handheld {
      flex-basis: calc(25% - 15px);

      /* &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        border-top: 1px solid $light-gray;
      }

      &:nth-child(4n + 2), &:nth-child(4n + 3), &:nth-child(4n + 4) {
        border-left: 1px solid $light-gray;
      } */
    }

    @media $small-handheld {
      @media $not-small-mobile {
        flex-basis: calc(50% - 15px);

        /* &:nth-child(1), &:nth-child(2) {
          border-top: 1px solid $light-gray;
        }

        &:nth-child(even) {
          border-left: 1px solid $light-gray;
        } */
      }
    }

    @media $small-mobile {
      flex-basis: calc(100% - 15px);
    }
  }

  &:not(.catalogue) {
    margin-left: 15px;
  }

  &.catalogue {
    margin-right: -15px;
    margin-bottom: 70px;
  }

  &.catalogue .product {
    flex-basis: calc(33.33% - 15px);
    //border-left: 1px solid $light-gray;

    /* &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      border-top: 1px solid $light-gray;
    }

    &:nth-child(3n + 3) {
      border-right: 1px solid $light-gray;
    } */

    // &:last-child:not(:nth-child(3n + 3)) {
    //   &:after {
    //     content: '';
    //     display: block;
    //     width: 1px; height: 100%;
    //     background: $light-gray;
    //     position: absolute;
    //     left: 100%; top: 0;
    //   }
    // }
  }

  .product--link {
    display: block;
    width: 100%; height: 100%;
    position: absolute !important;
    left: 0 !important;
    top: 0;
    z-index: 0;
  }

  .product--image {
    background: $black;
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      width: 100%; height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0; top: 0;
      transition: opacity .25s ease;
    }
  }

  .product--type {
    position: absolute;
    right: 7%; top: 7%;
    transform: rotate(-90deg);
    transform-origin: right bottom;
    font-size: 1.6rem;
    font-weight: normal;
    color: $gray;
    text-transform: lowercase;
  }

  .product--icons {
    position: absolute;
    right: 5%; top: 5%;
    z-index: 11;

    .item {
      width: 30px; height: @width;
      line-height: @height;
      text-align: center;
      border-radius: 50%;
      background: rgba($white, .5);
      color: $black;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .product--content {
    padding: 5px 0 30px;
  }

  .product--name {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 5px;

    @media $notebook {
      font-size: 1.1rem;
    }
  }

  .product--price {
    font-size: 1.6rem;
    font-weight: normal;

    @media $notebook {
      font-size: 1.3rem;
    }
  }

  .product--controls {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    z-index: 15;
    background: rgba($black, .5);
    opacity: 0;
    transition: opacity .25s ease;

    .is-touch-device & {
      display: none;
    }
  }

  .product:hover .product--controls {
    opacity: 1;
  }

  // .product:hover .product--image img {
  //   opacity: .7;
  // }

  .product--more-link,
  .product--fav-link {
    @extend .btn;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    font-weight: normal;
    color: $white;
    padding-top: 65px;

    .icon {
      width: 55px; height: @width;
      background: $black no-repeat;
      border-radius: 50%;
      position: absolute;
      left: 50%; top: 0;
      transform: translateX(-50%);
    }
  }

  .product--more-link {
    //right: 50%;
    //margin-right: 10px;

    // кнопка перемещена refs #47167
    left: calc(50% - 30px);

    .icon {
      background-image: url(/i/more-icon-white.png);
      background-position: 50%;
    }
  }

  .product--fav-link {
    // кнопка временно скрыта refs #47167
    display: none;
    left: 50%;
    margin-left: 10px;
    background: none;

    .icon {
      background-image: url(/i/fav-icon-white.png);
      background-position: 50% 60%;
    }
  }

  //vitrine product gallery
  &-gallery {

    .slick-list,
    .slick-track {
      height: 390px;
    }

    .slick-track {
      width: 100% !important;
    }

    ul.slick-dots {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      z-index: 15;
      font-size: 0;
      width: 100%;
      height: 45px;

      li {
        width: 15px;
        height: @width;
        border: 1px solid $black;
        border-radius: 50%;
        background-color: $white;
        cursor: pointer;
        transition: all .25s ease;

        &:hover {
          background-color: $gray;
        }

        &.slick-active {
          background-color: $black;
          // border: 1px solid $white;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }

        @media $handheld {
          width: 18px;
          height: @width;
        }
      }
    }
  }
}


.list-view .ias-trigger-prev{
  display: inline-block;
  position: absolute;

  a{
    font-size: 1.4rem;
    font-weight: 500;
    color: rgba(128,128,128,1);
    box-shadow: inset 0 0 0 2px rgba(128,128,128,1);
    background: transparent;
    line-height: 48px;
    height: 48px;
    display: inline-block;
    padding-left: 14px;
    padding-right: 14px;
    cursor: pointer;
    transition: 0.3s;
  }
  a:hover,
  a:focus{
    box-shadow: inset 0 0 0 2px rgba(196,196,196,1);
  }
}
.mobile-filters-link{
  display: none;
  text-align: right;

  a{
    font-size: 1.4rem;
    font-weight: 500;
    color: rgba(128,128,128,1);
    box-shadow: inset 0 0 0 2px rgba(128,128,128,1);
    background: transparent;
    line-height: 48px;
    height: 48px;
    display: inline-block;
    padding-left: 14px;
    padding-right: 24px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    margin-bottom: 16px;
    box-sizing: border-box;
    width: 160px;
    text-align: center;
  }
  a:hover,
  a:focus{
    box-shadow: inset 0 0 0 2px rgba(196,196,196,1);
  }
  a:after{
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    margin-top: 9px;
    margin-left: 8px;
    background-image: url('/i/filters-icon.svg');
    background-repeat: no-repeat;
    background-size: size('i/filters-icon.svg');
    background-position: 50%;
  }
}


@media only screen and (max-width: 1024px){
  .vitrine.catalogue .product {
    flex-basis: calc(33.33% - 15px);
  }
  .left-aside + .main-section {
    margin-left: 0;
  }
  .left-aside{
    position: absolute;
    display: none;
    left: 0px;
  }
  .left-aside-inner{
    padding: 75px 50px 70px 80px;
    background: #e8e8e8;
    transform: translate3d(0, 0, 0);
  }
  .mobile-filters-close{
    width: 48px;
    height: 48px;
    right: 32px;
    position: absolute;
    text-align: right;
    margin-top: -20px;
    margin-left: -8px;
    z-index: 100;
    cursor: pointer;
  }
  .mobile-filters-close:before,
  .mobile-filters-close:after{
    position: absolute;
    content: '';
    display: block;
    width: 32px;
    height: 2px;
    background-color: #000;
    margin-top: 24px;
    margin-left: 8px;
    z-index: -1;
  }
  .mobile-filters-close:before{
    transform: rotate(45deg);
  }
  .mobile-filters-close:after{
    transform: rotate(135deg);
  }
  .mobile-filters-link {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .vitrine.catalogue .product {
    background-color: transparent;
    flex-basis: calc(50% - 15px);

    /* &:nth-child(1), &:nth-child(2) {
      border-top: 1px solid $light-gray;
    }
    &:nth-child(3) {
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
    }
    &:nth-child(3n + 3) {
      border-right: 1px solid transparent;
    }
    &:nth-child(2n + 2) {
      border-right: 1px solid $light-gray;
    } */
    &:last-child:not(:nth-child(2n + 2)) {
      &:after {
        content: '';
        display: block;
        width: 1px; height: 100%;
        background: $light-gray;
        position: absolute;
        left: 100%; top: 0;
      }
    }
  }
}
@media only screen and (max-width: 568px) {
  .vitrine.catalogue .product {
    flex-basis: calc(100% - 15px);
    /* border-left: 1px solid $light-gray;
    border-right: 1px solid $light-gray; */

    /* &:nth-child(1){
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid transparent;
    }
    &:last-child() {
      border-bottom: 1px solid $light-gray;
    }
    &:nth-child(3n + 3){
      border-right: 1px solid $light-gray;
    } */
  }

  .list-view .ias-trigger-prev {
    position: relative;
    margin-bottom: 16px;
    display: block;

    a{
      width: 234px;
    }
  }
  .mobile-filters-link{
    text-align: center;

    a{
      width: 262px;
      padding-left: 0px;
      padding-right: 16px;
    }
  }
  .filter-label{
    text-align: center;
  }
}

